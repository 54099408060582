import { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../../context/ChatContext";
import { toast } from "react-toastify";
import { CompanyContext } from "../../../context/CompanyContext";
import UserIcon from "../../../assets/SVG/UserIcon";

export default function ConnectionCard(props) {
  const navigate = useNavigate();
  const { isChecked, onCheck } = props;
  const connection = props.user;
  const {
    setConnections,
    connections,
    acceptConnectionRequest,
    cancelConnectionRequest,
  } = useContext(ChatContext);

  const { companyData } = useContext(CompanyContext);

  function encrypt(url) {
    return btoa(url);
  }

  function removeConnectionFromState(connectionId) {
    setConnections(
      connections.filter((connection) => connection.id !== connectionId)
    );
  }

  function goToChat(event) {
    event.stopPropagation();
    navigate(`/inbox/${connection.id}`);
  }

  function goToProfile() {
    navigate(`/profile/${connection.id}`);
  }

  // function acceptRequest(event) {
  //   event.stopPropagation();
  //   acceptConnectionRequest(connection.id);
  //   toast.success("Connection Request Accepted!");
  //   removeConnectionFromState(connection.id);
  // }

  // function cancelRequest(event) {
  //   event.stopPropagation();
  //   cancelConnectionRequest(connection.id, connection.uid);
  //   removeConnectionFromState(connection.id);
  // }

  const tileClass = isChecked
    ? styles.creator_tile_layout_checked
    : styles.creator_tile_layout;

  return (
    <div className={tileClass} onClick={goToProfile}>
      <div className={styles.creator_tile_margin}>
        <div className={styles.checkbox_field}></div>
        <div className={styles.account_info}>
          <div className={styles.creator_profile_picture}>
            {connection?.profilePicture ? (
              <img
                className={styles.creator_profile_picture}
                src={connection?.profilePicture || "/DefaultAccountImage.png"}
                alt="profile"
              ></img>
            ) : (
              <UserIcon color={companyData.primary_color} />
            )}
          </div>

          <div className={styles.creator_display_names}>
            <p className={styles.creator_name}>
              {connection?.first_name} {connection?.lastname}
            </p>
            <p className={styles.creator_username}>
              {connection.admin ? "Admin" : "Member"}
            </p>
          </div>
        </div>
        <div className={styles.tile_date_section}></div>
      </div>
    </div>
  );
}
