import React from "react";
import { useEffect, useRef } from "react";
import styles from "./programs.module.css";
import { useState, useContext } from "react";
import { DynamicFormField } from "../../UniversalComponents/DynamicFormField/DynamicFormField";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import { UserList } from "../AdminUsersPage/adminUsersPage";
import { CompanyContext } from "../../../context/CompanyContext";

export default function ViewSubscribersPage(props) {
  const [deletePopup, setDeletePopup] = useState(false);

  const { companyData } = useContext(CompanyContext);

  const [file, setFile] = useState(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);

  const [program, setProgram] = useState(props.selectedProgram);

  const updateField = (field, value) => {
    setProgram({ ...program, [field]: value });
  };

  let canCreate =
    program?.title && program?.description && program?.program_type;

  const [isCreating, setIsCreating] = useState(false);

  const programCategories = [
    "Workshops & Seminars",
    "Conferences & Summits",
    "Webinars & Online Events",
    "Community & Networking",
    "Training & Development",
    "Competitions & Challenges",
    "Exhibitions & Fairs",
    "Fundraisers & Campaigns",
    "Volunteering Opportunities",
    "Publications & Articles",
    "Tools & Applications",
    "Other",
  ];

  async function handleSave() {
    setIsCreating(true);
    try {
      await props.saveProgram(program);
      props.back();
    } catch (error) {
      console.error("Error saving program:", error);
    }
    setIsCreating(false);
  }

  return (
    <div className={styles.edit_guideline_modal}>
      <div className={styles.top_spacer}></div>
      <UserList filteredUsers={[]} companyData={companyData} />
    </div>
  );
}
