import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./feed.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { FeedContext } from "../../../context/FeedContext";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import Post from "./feed_post";
import NewPostPrompt from "./feed_create_new_post";
import FeedPageSuggested from "./feed_page_suggested";
import FeedSidebar from "./feed_page_sidebar";
import colorAlphaConverter from "../../../utils/colorAlphaConverter";
import { CompanyContext } from "../../../context/CompanyContext";

function Feed() {
  const { feed, matchedUsers, loadingFeed } = useContext(FeedContext);
  const { profile } = useContext(AuthContext);
  const { companyData } = useContext(CompanyContext);

  const primaryColor = colorAlphaConverter(companyData.primary_color, 1);
  const primaryColorQuarter = colorAlphaConverter(
    companyData.primary_color,
    0.25
  );
  const primaryColorLight = colorAlphaConverter(
    companyData.primary_color,
    0.05
  );
  return (
    <div
      className={styles.main_layout}
      style={{
        "--primary_color": primaryColor,
        "--primary_color_quarter": primaryColorQuarter,
        "--primary_color_light": primaryColorLight,
      }}
    >
      <div className={styles.page_layout}>
        {/* <SearchBarHeader /> */}
        <div className={styles.main_feed_section}>
          <NewPostPrompt profile={profile} color={companyData.primary_color} />
          {/* <div className={styles.page_divider}></div> */}
          <div className={styles.feed}>
            {feed.map((post, index) => (
              <Post key={index} {...post} color={companyData.primary_color} />
            ))}
          </div>
        </div>
        <FeedPageSuggested title="People You May Know" />
      </div>
      <FeedSidebar />
    </div>
  );
}

export default Feed;
