import React from "react";
import { useContext } from "react";

import styles from "../FeedPage/feed.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { FeedContext } from "../../../context/FeedContext";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";

function FavoritedResources(props) {
  const { feed } = useContext(FeedContext);

  return (
    <div className={styles.resources_sidebar}>
      <p className={styles.subscribed_programs}>
        {props?.profile?.first_name}'s Liked Posts
      </p>
      {/* <ExampleResource />
      <ExampleResource /> */}
    </div>
  );
}

function ExampleResource() {
  return (
    <div className={styles.promotion_tile}>
      {/* <div className={styles.promotion_top_info}>
        <img
          src="/ImpactReportCover.png"
          className={styles.promotion_image}
        ></img>
      </div> */}
      <div className={styles.no_image_title_text}>
        <p className={styles.promotion_title}>Example Resource Title</p>
      </div>
      <p className={styles.promotion_description}>
        Example Resource Description and Information{" "}
      </p>
      <div className={styles.promotion_controls}>
        <div className={styles.check_out_button}>Open Resource</div>
      </div>
    </div>
  );
}

export default FavoritedResources;
