import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./settings_tabs.module.css";
import { AuthContext } from "../../../../context/AuthContext";

export default function SubscriptionSettings(props) {
  const { fetchPaymentPortalLink } = useContext(AuthContext);
  const profile = props.profile;
  console.log(profile);
  // async function handleManageSubscription() {
  //   try {
  //     const portalURL = await fetchPaymentPortalLink(
  //       profile.uid,
  //       profile.paymentInfo.stripeId
  //     );
  //     console.log("Payment Portal URL:", portalURL);

  //     window.open(portalURL, "_blank");
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }

  const CreditCard = ({ paymentMethod }) => {
    return (
      <div className={styles.credit_card}>
        <div className={styles.card_top_info}>
          <img
            className={styles.chip_image}
            src="PaymentOptionsImages/chip.png"
            alt="brand"
          ></img>

          <img
            className={styles.card_brand_image}
            src="PaymentOptionsImages/visaImage.png"
            alt="brand"
          ></img>
        </div>
        <div className={styles.card_middle}>
          <p className={styles.card_number}>
            •••• •••• •••• {paymentMethod.last4}
          </p>
        </div>
        <div className={styles.card_bottom_info}>
          <div className={styles.card_info_left}>
            <p className={styles.card_title}>Name</p>
            <p className={styles.card_value}>
              {profile.firstName} {profile.lastName}
            </p>
          </div>
          <div className={styles.card_info_right}>
            <p className={styles.card_title}>Expires</p>
            <p className={styles.card_value}>
              {paymentMethod.cardExpryMonth} / {paymentMethod.cardExpryYear}
            </p>
          </div>
        </div>
      </div>
    );
  };

  function CardBrandImage() {}

  return (
    <div className={styles.main_layout}>
      <div className={styles.payment_section_button}>
        <div className={styles.payment_plan_info}>
          <p className={styles.section_title}>Current Plan</p>
          <div className={styles.subscription_options}>
            <CurrentPlanTile profile={profile} />
            <UpgradePlanTile profile={profile} />
          </div>
        </div>
        <div
          className={styles.manage_subscription_button}
          // onClick={handleManageSubscription}
        >
          Manage Subscription
        </div>
      </div>
      <div className={styles.payment_section}>
        <p className={styles.section_title}>Payment Methods</p>
        {/* {profile?.paymentInfo?.paymentMethods.map((paymentMethod, index) => (
          <CreditCard paymentMethod={paymentMethod} />
        ))} */}
      </div>
    </div>
  );
}

function CurrentPlanTile(props) {
  const profile = props.profile;
  return (
    <div className={styles.subscription_option_current}>
      <img src="/check.png" alt="Check" className={styles.check_icon} />
      <div className={styles.subscription_option_top}>
        <p className={styles.plan_type}>Standard</p>
        <p className={styles.price_point}>
          <span className={styles.bolder}>
            {/* {formatPrice(profile.paymentInfo.pricePoint)} */}
          </span>
          {/* /{profile.paymentInfo.interval} */}
        </p>
      </div>
      <div className={styles.subscription_option_middle}>
        <p className={styles.plan_renews}>
          Your plan renews on{" "}
          {/* {formatUnixTimestamp(profile.paymentInfo.billingCycle[1])} */}s
        </p>
      </div>
      <div className={styles.subscription_option_bottom}>
        <div className={styles.current_plan_box}>Current</div>
        {/* <p className={styles.learn_more}>Learn More</p> */}
      </div>
    </div>
  );
}

function UpgradePlanTile(props) {
  const profile = props.profile;
  return (
    <div className={styles.subscription_option_upgrade}>
      <div className={styles.subscription_option_top}>
        <p className={styles.plan_type}>Premium</p>
        <p className={styles.price_point}>
          <span className={styles.bolder}>
            {/* {formatPrice(profile.paymentInfo.pricePoint)} */}
          </span>
          {/* /{profile.paymentInfo.interval} */}
        </p>
      </div>
      <div className={styles.subscription_option_middle}>
        <p className={styles.plan_renews}>
          Your plan renews on{" "}
          {/* {formatUnixTimestamp(profile.paymentInfo.billingCycle[1])} */}
        </p>
      </div>
      <div className={styles.subscription_option_bottom}>
        <div className={styles.upgrade_button}>Upgrade</div>
        {/* <p className={styles.learn_more}>Learn More</p> */}
      </div>
    </div>
  );
}

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

function formatPrice(priceInCents, currency = "USD") {
  const priceInDollars = priceInCents / 100;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(priceInDollars);
}
