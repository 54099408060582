import { db } from "../services/firebase";
import { doc } from "firebase/firestore";

export const extractDatabaseReferance = () => {
  const path = window.location.pathname.split("/");
  const domainName = path.length > 1 ? path[1] : null;
  const collection = "Accounts";
  const databaseReferance = doc(db, collection, domainName);
  return databaseReferance;
};
