export default function colorAlphaConverter(color, alpha) {
  // This regex matches both RGB and RGBA formats
  const regex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/;

  const match = color.match(regex);

  if (match) {
    // Extract the red, green, and blue values
    const [_, red, green, blue] = match;

    // Return the color in RGBA format with the new alpha value
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  } else {
    console.error("Invalid color format:", color);
    return color; // Return the original input if it's not in a valid format
  }
}
