import React from "react";

const LinkIcon = ({ width = 18, height = 18, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    id="fi_2985013"
    enable-background="new 0 0 64 64"
    width={width}
    height={height}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g id="ARC_176_">
        <g>
          <path
            d="m36.243 29.758c-.512 0-1.024-.195-1.414-.586-3.119-3.119-8.194-3.12-11.314 0-.78.781-2.048.781-2.828 0-.781-.781-.781-2.047 0-2.828 4.679-4.68 12.292-4.679 16.97 0 .781.781.781 2.047 0 2.828-.39.391-.903.586-1.414.586z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="ARC_175_">
        <g>
          <path
            d="m34.829 41.167c-3.073 0-6.146-1.17-8.485-3.509-.781-.781-.781-2.047 0-2.828.78-.781 2.048-.781 2.828 0 3.119 3.119 8.194 3.12 11.314 0 .78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-2.34 2.339-5.413 3.509-8.485 3.509z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="LINE_273_">
        <g>
          <path
            d="m41.899 38.243c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l11.172-11.172c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-11.172 11.172c-.39.391-.902.586-1.414.586z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="LINE_272_">
        <g>
          <path
            d="m25.071 55.071c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l6.245-6.245c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-6.245 6.245c-.39.391-.902.586-1.414.586z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="LINE_271_">
        <g>
          <path
            d="m10.929 40.929c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l11.172-11.171c.781-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-11.172 11.171c-.391.39-.903.586-1.414.586z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="LINE_270_">
        <g>
          <path
            d="m32.684 19.175c-.512 0-1.023-.195-1.414-.585-.781-.781-.781-2.047 0-2.829l6.245-6.246c.781-.781 2.047-.781 2.829 0 .781.781.781 2.047 0 2.829l-6.245 6.246c-.391.389-.904.585-1.415.585z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="ARC_174_">
        <g>
          <path
            d="m18 57.935c-3.093 0-6.186-1.15-8.485-3.45-4.6-4.6-4.6-12.371 0-16.971.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-3.066 3.066-3.066 8.248 0 11.314s8.248 3.066 11.314 0c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828-2.299 2.301-5.392 3.451-8.485 3.451z"
            fill={color}
          ></path>
        </g>
      </g>
      <g id="ARC_173_">
        <g>
          <path
            d="m53.071 27.071c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828 3.066-3.066 3.066-8.248 0-11.314s-8.248-3.066-11.314 0c-.78.781-2.048.781-2.828 0-.781-.781-.781-2.047 0-2.828 4.6-4.6 12.371-4.6 16.971 0s4.6 12.371 0 16.971c-.391.39-.903.585-1.415.585z"
            fill={color}
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default LinkIcon;
