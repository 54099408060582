import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Resources from "../../components/Portal/ResourcesPage/resources";

const ResourcesPage = () => {
  return (
    <>
      <Sidebar childComponent={<Resources />} activeText="Resources" />
    </>
  );
};

export default ResourcesPage;
