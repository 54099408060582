import React from "react";
import { useState, useContext } from "react";
import { CompanyContext } from "../../../context/CompanyContext";

import styles from "./auth.module.css";

const SignUpComponentSidebar = () => {
  const { getPrimaryColorWithOpacity } = useContext(CompanyContext);
  return (
    <div
      className={styles.authentication_content_side}
      style={{ backgroundColor: getPrimaryColorWithOpacity(1) }}
    >
      {/* <p className={styles.authentication_content_text}>
        Nexus Makes Building Your Brand Easier.<br></br>
        <br></br>
        <strong>Period.</strong>
      </p> */}
    </div>
  );
};

export default SignUpComponentSidebar;
