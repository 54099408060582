import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ConnectionComponent from "../../components/Portal/ConnectionComponents/ConnectionComponent";

const ConnectionsPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<ConnectionComponent />}
        activeText="Connections"
      />
    </>
  );
};

export default ConnectionsPage;
