import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";
import { useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { CompanyContext } from "../../../context/CompanyContext";
import { useNavigate } from "react-router";
import CircularLoader from "../CircularLoader/CircularLoader";
import SignUpComponentSidebar from "./SignUpComponentSidebar";
import { useSubdomainNavigate } from "../../../utils/subdomainNavigate";
import colorAlphaConverter from "../../../utils/colorAlphaConverter";

const SignInComponent = () => {
  const navigate = useNavigate();
  const subdomainNavigate = useSubdomainNavigate();
  const { signIn } = useContext(AuthContext);
  const { companyData } = useContext(CompanyContext);
  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);

  function handleSignUp() {
    navigate("/signup");
  }

  function handleForgotPassword() {
    navigate("/forgot-password");
  }

  async function handleSubmit() {
    try {
      await signIn(email, password);
      subdomainNavigate(`/community-forum`);
    } catch (error) {
      console.error("Login failed:", error);
      setError(error.response?.data?.message || "Invalid Credentials");
    }
  }

  const canContinue = email !== "" && password !== "";
  //rgb(243, 201, 60)

  const primaryColor = colorAlphaConverter(companyData.primary_color, 1);

  const primaryQuarterColor = colorAlphaConverter(
    companyData.primary_color,
    0.25
  );

  return (
    <div
      className={styles.authentication_page}
      style={{
        "--primary-color": primaryColor,
        "--primary-quarter-color": primaryQuarterColor,
      }}
    >
      <div className={styles.authentication_layout}>
        <div className={styles.component_side_layout}>
          <div className={styles.sidebar}>
            <img
              alt="logo"
              src={companyData?.logo_image}
              className={styles.logo_icon}
            ></img>
            <p className={styles.signin_page_title}>Welcome Back</p>
            <p className={styles.login_input_titles}>Email Address</p>
            <div className={styles.email_input}>
              <img
                className={styles.auth_input_icon}
                alt="email"
                src="/EmailIcon.png"
              ></img>
              <input
                onChange={(event) => setEmail(event.target.value)}
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>Password</p>
            <div className={styles.email_input}>
              <img
                className={styles.auth_input_icon}
                alt="password"
                src="/PasswordIcon.png"
              ></img>
              <input
                type="password"
                onChange={(event) => setPassword(event.target.value)}
                className={styles.input}
              ></input>
            </div>
            {error && <p className={styles.error_text}>{error}</p>}
            {canContinue ? (
              <div onClick={handleSubmit} className={styles.page_button}>
                {/* <CircularLoader/> */}
                <p className={styles.join_button_text}>Sign In</p>
              </div>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Sign In</p>
              </div>
            )}
            {/* <div className={styles.prompt_texts}>
              <p className={styles.note}>
                Don't have an account?{" "}
                <span className={styles.clickable_text} onClick={handleSignUp}>
                  Sign Up
                </span>
              </p>
            </div> */}
          </div>
        </div>
        {showSidebar && <SignUpComponentSidebar />}
      </div>
    </div>
  );
};

export default SignInComponent;
