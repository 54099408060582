import React, { useEffect, useState, useContext } from "react";
import styles from "./programs.module.css";
import SearchbarHeader from "../../UniversalComponents/SearchbarHeader/SearchbarHeader";
import CreateProgram from "./CreateProgram";
import ViewSubscribersPage from "./ViewSubscribersPage";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import { useNavigate } from "react-router";
import MoreButton from "../../UniversalComponents/MoreButton/MoreButton";
import { AdminPortalContext } from "../../../context/AdminPortalContext";
import LinkIcon from "../../../assets/SVG/LinkIcon";
import TrashIcon from "../../../assets/SVG/TrashIcon";

import { FeedContext } from "../../../context/FeedContext";
import { CompanyContext } from "../../../context/CompanyContext";
import PinIcon from "../../../assets/SVG/PinIcon";

const AdminProgramsPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [viewingSubscribers, setViewingSubscribers] = useState(false); // State to manage viewing subscribers

  const [searchTerm, setSearchTerm] = useState("");
  const { programs, setPrograms } = useContext(FeedContext);
  const { createProgram } = useContext(AdminPortalContext);

  async function saveProgram(newProgram) {
    const previousPrograms = [...programs];
    try {
      if (isUpdating) {
        setPrograms((prev) =>
          prev.map((item) => (item.id === newProgram.id ? newProgram : item))
        );
      } else {
        newProgram.timestamp = Date.now();
        newProgram.pinned = false;
        await createProgram(newProgram);
        setPrograms((prev) => [newProgram, ...prev]);
      }
    } catch (error) {
      console.error("Error saving program:", error);
      setPrograms(previousPrograms);
      throw error;
    }
  }

  function handleModalNav() {
    setIsEditing(!isEditing);
  }

  function handleEdit(program) {
    setIsUpdating(true);
    setSelectedProgram(program);
    setIsEditing(true);
  }

  function handleViewSubscribers() {
    setViewingSubscribers(true); // Logic to view subscribers
  }

  function back() {
    if (viewingSubscribers) {
      setViewingSubscribers(false);
    } else {
      setIsUpdating(false);
      setIsEditing(false);
      setSelectedProgram(null);
    }
  }

  return (
    <div className={styles.main_layout}>
      <>
        {!isEditing && !viewingSubscribers ? (
          <p className={styles.page_title_margin}>Manage Programs</p>
        ) : (
          <div className={styles.title_area_create}>
            <div className={styles.back_button_small} onClick={back}>
              <img
                className={styles.back_icon_opacity}
                src={"/images/BackIcon.png"}
                alt="back"
              ></img>
            </div>
            <p className={styles.page_title}>
              {viewingSubscribers ? "View Subscribers" : "Create Program"}
            </p>
          </div>
        )}
        <div className={styles.edit_guidelinees_modal}>
          {!isEditing && !viewingSubscribers ? (
            <>
              <SearchbarHeader
                type="AdminUsers"
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                create={true}
                createFunction={handleModalNav}
              />
              <div className={styles.divider}></div>

              {programs && programs.length !== 0 && (
                <div className={styles.guideline_layout}>
                  {programs.map((program, index) => {
                    return (
                      <Program
                        program={program}
                        index={index}
                        handleEdit={handleEdit}
                        viewSubscribersFunction={handleViewSubscribers}
                      />
                    );
                  })}
                </div>
              )}
              <div className={styles.bottom_section_divider}></div>
            </>
          ) : viewingSubscribers ? (
            <ViewSubscribersPage back={back} />
          ) : (
            <CreateProgram
              selectedProgram={selectedProgram}
              back={back}
              saveProgram={saveProgram}
            />
          )}
        </div>
      </>
    </div>
  );
};

function Program({ program, index, handleEdit, viewSubscribersFunction }) {
  const { companyData } = useContext(CompanyContext);
  const navigate = useNavigate();
  const onEditClick = () => {
    handleEdit(program);
  };
  const viewSubscribers = () => {
    viewSubscribersFunction();
  };
  return (
    <div className={styles.program_layout} key={index}>
      <div className={styles.model_information_top}>
        <div className={styles.model_information}>
          <div className={styles.model_info_section}>
            <p className={styles.program_title}>{program.title}</p>
            <p className={styles.program_type_title}>{program.program_type}</p>
            <p className={styles.program_description_text}>
              {program.description}
            </p>
          </div>
          {/* <div className={styles.parmater_info_section}>
            <p className={styles.model_section_title}>Information</p>
            <p className={styles.model_section_text}>Type: {program.type}</p>
            <p className={styles.model_section_text}>
              Date: {program.dateTime}
            </p>
          </div> */}
        </div>
        <div className={styles.control_buttons}>
          <div className={styles.trash_icon}>
            <TrashIcon />
          </div>
          <div className={styles.pinned_icon}>
            <PinIcon />
          </div>
        </div>
      </div>
      <div className={styles.model_information_bottom}>
        <div className={styles.view_information_buttons}>
          <div className={styles.training_data} onClick={viewSubscribers}>
            View Subscribers
          </div>
          <div className={styles.training_data} onClick={onEditClick}>
            Edit Program
          </div>
        </div>

        <div className={styles.view_information_buttons}>
          <div className={styles.learn_more}>
            Learn More
            <LinkIcon color={companyData.primary_color} />
          </div>
          {/* <div className={styles.delete}>Delete</div> */}
        </div>
      </div>
    </div>
  );
}

export default AdminProgramsPage;
