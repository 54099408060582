import React from "react";
import { useContext, useState, useEffect } from "react";
import styles from "./announcements.module.css";
import SearchBarHeader from "../../UniversalComponents/SearchbarHeader/SearchbarHeader";
import { AdminPortalContext } from "../../../context/AdminPortalContext";
import { CompanyContext } from "../../../context/CompanyContext";
import { FeedContext } from "../../../context/FeedContext";
import { ConfirmDeletePopup } from "../../ScreenPopups/DeletePopup/ConfirmDeletePopup";
import { ManageUserPopup } from "../../ScreenPopups/ManageUserPopup/ManageUserPopup";
import MoreButton from "../../UniversalComponents/MoreButton/MoreButton";
import TrashIcon from "../../../assets/SVG/TrashIcon";
import formatTimestamp from "../../../utils/formatTimeStamp";
import formatUnixTimestamp from "../../../utils/formatUnixTimestamp";

function AdminAnnouncementsPage() {
  const { handleAnnouncement, deleteAnnouncementById } =
    useContext(AdminPortalContext);
  const { announcements, setAnnouncements } = useContext(FeedContext);
  const { companyData } = useContext(CompanyContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [currentAnnouncementId, setCurrentAnnouncementId] = useState();

  async function handleAnnouncementPost(subject, announcementText) {
    const newAnnouncement = {
      subject: subject,
      announcement: announcementText,
      timestamp: Date.now(),
    };

    try {
      setAnnouncements((prevAnnouncements) => [
        newAnnouncement,
        ...prevAnnouncements,
      ]);

      await handleAnnouncement(newAnnouncement);
    } catch (error) {
      console.error("Error posting:", error);

      setAnnouncements((prevAnnouncements) =>
        prevAnnouncements.filter(
          (a) => a.timestamp !== newAnnouncement.timestamp
        )
      );
    }
  }

  const deleteAnnouncement = async (id) => {
    try {
      await deleteAnnouncementById(id);
      setAnnouncements((prev) =>
        prev.filter((announcement) => announcement.id !== id)
      );
      console.log("Deleted successfully");
    } catch (error) {
      console.error("Error deleting program:", error);
      throw error;
    }
  };

  const handleDeleteClick = (announcementId) => {
    setDeletePopupOpen(true);
    setCurrentAnnouncementId(announcementId);
  };

  return (
    <div className={styles.main_layout}>
      <ConfirmDeletePopup
        openModal={deletePopupOpen}
        closeModal={() => setDeletePopupOpen(false)}
        actionFunction={() => {
          deleteAnnouncement(currentAnnouncementId);
        }}
        desc={"Are you sure you want to delete?"}
      />
      <p className={styles.page_title}>Announcements</p>
      <NewAnnouncement handleAnnouncementPost={handleAnnouncementPost} />
      {announcements.length !== 0 && (
        <div className={styles.prospect_scroll}>
          {announcements.map((announcement, index) => {
            return (
              <Announcement
                key={index} // assuming your announcements have a unique id field
                announcement={announcement}
                deleteAnnouncement={() => handleDeleteClick(announcement.id)}
              />
            );
          })}
          <div className={styles.buffer}></div>
        </div>
      )}
    </div>
  );
}
function NewAnnouncement({ handleAnnouncementPost }) {
  const [subject, setSubject] = useState("");
  const [announcementText, setAnnouncementText] = useState("");

  const isPostButtonDisabled =
    subject.trim() === "" || announcementText.trim().length < 3;

  const postAnnouncement = async () => {
    if (!isPostButtonDisabled) {
      await handleAnnouncementPost(subject, announcementText);
      // Clear the inputs after successful posting
      setSubject("");
      setAnnouncementText("");
    }
  };

  return (
    <div className={styles.new_announcement_layout}>
      <div className={styles.announcement_content}>
        <input
          className={styles.subject}
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <div className={styles.divider}></div>
        <textarea
          className={styles.description}
          placeholder="Type announcement..."
          value={announcementText}
          onChange={(e) => setAnnouncementText(e.target.value)}
        />
      </div>
      <div className={styles.new_announcement_right}>
        <div
          className={styles.new_announcement_post_button}
          onClick={postAnnouncement}
          style={{ opacity: isPostButtonDisabled ? 0.3 : 1 }}
        >
          Post Announcement
        </div>
      </div>
    </div>
  );
}

function Announcement({ announcement, deleteAnnouncement }) {
  return (
    <div className={styles.announcement_layout}>
      <div className={styles.announcement_content}>
        <p className={styles.announcement_subject}>{announcement.subject}</p>
        <p className={styles.announcement_description}>
          {announcement.announcement}
        </p>
      </div>
      <div className={styles.announcement_right}>
        <div className={styles.trash_icon} onClick={deleteAnnouncement}>
          <TrashIcon />
        </div>
        {/* <MoreButton /> */}

        <p className={styles.announcement_posted_at}>
          Posted {formatUnixTimestamp(announcement.timestamp)}
        </p>
      </div>
    </div>
  );
}

export default AdminAnnouncementsPage;

// const announcements = [
//   {
//     subject: "Scheduled System Maintenance",
//     announcement:
//       "Our system will be undergoing scheduled maintenance on April 5th, 2024, from 12:00 AM to 4:00 AM UTC. During this period, some services may be intermittently unavailable.",
//     timestamp: "2024-04-01T10:00:00Z",
//   },
//   {
//     subject: "New Feature Release",
//     announcement:
//       "We're excited to announce the release of our new chat feature, which will be available starting April 10th, 2024. This feature will allow you to communicate in real-time with other users.",
//     timestamp: "2024-04-03T09:00:00Z",
//   },
//   {
//     subject: "Service Downtime Apology",
//     announcement:
//       "We apologize for the unexpected downtime that occurred on March 30th, 2024. We have identified and resolved the issue, and we're taking steps to prevent such occurrences in the future.",
//     timestamp: "2024-03-31T15:00:00Z",
//   },
//   {
//     subject: "Privacy Policy Update",
//     announcement:
//       "Our Privacy Policy has been updated to better reflect our use of data and your rights as a user. These changes will take effect on May 1st, 2024. We encourage you to review the updated policy.",
//     timestamp: "2024-04-15T11:30:00Z",
//   },
//   {
//     subject: "User Feedback Survey",
//     announcement:
//       "We value your feedback! Please take a moment to complete our user experience survey. Your insights will help us improve our services. The survey will be open until April 20th, 2024.",
//     timestamp: "2024-04-05T12:00:00Z",
//   },
// ];
