import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import styles from "./admin.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import AdminUsersPage from "../../components/Admin/AdminUsersPage/adminUsersPage";
import { AdminPortalProvider } from "../../context/AdminPortalContext";
import AdminSettings from "../../components/Admin/AdminSettingsPage/settings_component";
import { useSubdomainNavigate } from "../../utils/subdomainNavigate";
import colorAlphaConverter from "../../utils/colorAlphaConverter";
import { CompanyContext } from "../../context/CompanyContext";
import AdminAnnouncementsPage from "../../components/Admin/AdminAnnouncementsPage/AdminAnnouncementsPage";
import AdminProgramsPage from "../../components/Admin/AdminProgramsPage/AdminProgramsPage";
import LockIcon from "../../assets/SVG/LockIcon";

const AdminPage = () => {
  const { companyData } = useContext(CompanyContext);

  const menuItems = [
    {
      label: "Your Users",
      key: "manage-users",
      component: AdminUsersPage,
    },
    {
      label: "Announcements",
      key: "manage-announcements",
      component: AdminAnnouncementsPage,
    },
    {
      label: "Manage Programs",
      key: "manage-programs",
      component: AdminProgramsPage,
    },
    // {
    //   label: "Manage Resources",
    //   key: "manage-resources",
    //   component: AdminUsersPage,
    // },
    {
      label: "Your Events",
      key: "admin-disabled",
      component: AdminSettings,
      disabled: true,
    },
    {
      label: "Storefront",
      key: "admin-disabled",
      component: AdminSettings,
      disabled: true,
    },
    {
      label: "Nexus Ai",
      key: "admin-disabled",
      component: AdminSettings,
      disabled: true,
    },
    {
      label: "Nexus Settings",
      key: "admin-settings",
      component: AdminSettings,
    },
  ];

  const subdomainNavigate = useSubdomainNavigate();
  const location = useLocation();

  const [activeComponentKey, setActiveComponentKey] = useState(
    menuItems[0].key
  );

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.key)
    );
    if (activeItem) {
      setActiveComponentKey(activeItem.key);
    }
  }, [location]);

  const renderComponent = () => {
    const activeItem = menuItems.find(
      (item) => item.key === activeComponentKey
    );
    return activeItem ? <activeItem.component /> : null;
  };

  const handleMenuItemClick = (key) => {
    setActiveComponentKey(key);
    subdomainNavigate(`/admin/${key}`);
  };

  const primaryColor = colorAlphaConverter(companyData.primary_color, 1);

  const primaryQuarterColor = colorAlphaConverter(
    companyData.primary_color,
    0.25
  );

  const primaryColorHover = colorAlphaConverter(companyData.primary_color, 0.1);

  const sidebarBgColor = colorAlphaConverter(companyData.primary_color, 0.1);

  const lightBackground = colorAlphaConverter(companyData.primary_color, 0.05);

  const menuItemHoverBgColor = colorAlphaConverter(
    companyData.primary_color,
    0.04
  );
  const menuItemHoverColor = colorAlphaConverter(
    companyData.primary_color,
    0.7
  );
  const menuItemSelectedBgColor = colorAlphaConverter(
    companyData.primary_color,
    0.1
  );
  const menuItemSelectedColor = colorAlphaConverter(
    companyData.primary_color,
    1
  );

  return (
    <>
      <AdminPortalProvider>
        <Sidebar
          admin={true}
          childComponent={
            <div
              className={styles.page_layout}
              style={{
                "--primary-color": primaryColor,
                "--primary-quarter-color": primaryQuarterColor,
                "--primary-color-hover": primaryColorHover,
                "--sidebar-bg-color": sidebarBgColor,
                "--menu-item-hover-bg-color": menuItemHoverBgColor,
                "--menu-item-hover-color": menuItemHoverColor,
                "--menu-item-selected-bg-color": menuItemSelectedBgColor,
                "--menu-item-selected-color": menuItemSelectedColor,
                "--light-background": lightBackground,
              }}
            >
              <div className={styles.sidebar}>
                {menuItems.map((item) => (
                  <div
                    key={item.key}
                    onClick={() =>
                      !item.disabled && handleMenuItemClick(item.key)
                    }
                    className={`${styles.menu_item} ${
                      activeComponentKey === item.key
                        ? styles.menu_item_selected
                        : ""
                    } ${item.disabled ? styles.menu_item_disabled : ""}`}
                  >
                    {item.label}
                    {item.disabled && (
                      <div className={styles.locked_icon}>
                        <LockIcon width={15} height={15} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div style={{ flex: 1 }}>{renderComponent()}</div>
            </div>
          }
          activeText="Admin"
        />
      </AdminPortalProvider>
    </>
  );
};

export default AdminPage;
