import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { CompanyContext } from "./context/CompanyContext";
import LoadingPage from "./pages/LoadingPage";
import { extractSubdomain } from "./utils/extractSubdomain";

function App(props) {
  const navigate = useNavigate();
  const {
    companyData,
    companyLoading,
    setCompanyLoading,
    fetchCompanyData,
    setCompanyDomain,
  } = useContext(CompanyContext);
  const location = useLocation();
  // const { authencation } = useContext(AuthContext);

  // useEffect(() => {
  //   const domainName = extractDomainNameFromUrl();
  //   console.log("Fetching data for domainName:", domainName);
  //   if (domainName) {
  //     setCompanyDomain(domainName);
  //     fetchCompanyData(domainName);
  //   }
  // });

  useEffect(() => {
    console.log("Updated companyData:", companyData);
  }, [companyData]);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/request-access" ||
      location.pathname === "/"
    ) {
      setCompanyLoading(false);
      return;
    }

    const domainName = extractSubdomain();
    console.log("domainName", domainName);
    if (domainName) {
      fetchCompanyData(domainName).then((isFound) => {
        if (!isFound) {
          navigate("/no-nexus-found");
        } else {
          // authencation(domainName);
        }
      });
    } else {
      navigate("/no-nexus-found");
    }
  }, []);

  if (companyLoading) {
    return <LoadingPage />;
  }

  const { childComp } = props;
  return <div className="App">{childComp}</div>;
}

export default App;
