import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Programs from "../../components/Portal/ProgramsPage/ProgramsPage";

const ProgramsPage = () => {
  return (
    <>
      <Sidebar childComponent={<Programs />} activeText="Programs" />
    </>
  );
};

export default ProgramsPage;
