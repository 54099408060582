import React from "react";

const ShopIcon = ({ width = 20, height = 20, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    id="fi_2430422"
    enable-background="new 0 0 448 448"
    width={width}
    height={height}
    viewBox="0 0 448 448"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g id="bag">
        <path d="m383.921 143.04c-.508-8.46-7.525-15.055-16-15.04h-64v-48c0-44.183-35.817-80-80-80s-80 35.817-80 80v48h-64c-8.475-.015-15.492 6.58-16 15.04l-14.08 254.24c-1.502 26.467 18.736 49.141 45.203 50.643.931.053 1.864.079 2.797.077h252.32c26.51.043 48.034-21.413 48.077-47.923.002-.933-.024-1.866-.077-2.797zm-208-63.04c0-26.51 21.49-48 48-48s48 21.49 48 48v48h-96z"></path>
      </g>
    </g>
  </svg>
);

export default ShopIcon;
