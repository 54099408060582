import React, { useState, useRef, useEffect } from "react";
import styles from "./dynamicfield.module.css";
import { Tooltip } from "@mui/material";

export const DynamicFormField = ({
  title,
  type = "text",
  paragraph = false,
  error = "",
  name,
  info,
  content,
  dropdown = false,
  dropDownData,
  onValueChange,
  numberOnly = false,
  receiveObject,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible]);

  const handleOptionClick = (option, event) => {
    event.stopPropagation(); // Add this line
    onValueChange(option);
    console.log("Handle Option Click");
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    if (numberOnly) {
      const value = event.target.value;
      if (value === "" || /^\d+$/.test(value)) {
        onValueChange(value);
      }
    } else {
      onValueChange(event.target.value);
    }
  };

  const inputBoxStyle = dropdown ? styles.input_box_dropdown : styles.input_box;

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>
        {info && (
          <Tooltip title={info} arrow>
            <img
              src="/moreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
          </Tooltip>
        )}
      </div>
      <div className={paragraph ? styles.input_box_paragraph : inputBoxStyle}>
        {paragraph ? (
          <textarea
            name={name}
            className={styles.input_box_text}
            value={content}
            onClick={() => {
              dropdown && toggleDropdown();
            }}
            onChange={(e) => !dropdown && handleInputChange(e)}
            type={numberOnly ? "number" : type}
          />
        ) : (
          <input
            name={name}
            className={styles.input_box_text}
            value={content}
            onClick={() => {
              dropdown && toggleDropdown();
            }}
            onChange={(e) => !dropdown && handleInputChange(e)}
            type={numberOnly ? "number" : type}
          />
        )}

        {dropdown && (
          <div
            className={styles.input_box_dropdown_button}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg} ref={dropdownRef}>
              <img
                src="/DropDownArrow.png"
                alt="options"
                className={`${styles.drop_down_image} ${
                  isDropdownVisible ? styles.rotate_180 : ""
                }`}
              />
              {isDropdownVisible && (
                <div className={styles.dropdown_menu}>
                  {dropDownData?.map((item, i) => (
                    <div
                      key={i}
                      className={styles.dropdown_menu_option_box}
                      onClick={(e) => handleOptionClick(item, e)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <p className={styles.error_text}>{error}</p>}
    </div>
  );
};
