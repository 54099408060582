import AdminIcon from "../../assets/SVG/SidebarIcons/AdminIcon";
import DashboardIcon from "../../assets/SVG/SidebarIcons/DashboardIcon";

import ChatIcon from "../../assets/SVG/SidebarIcons/ChatIcon";
import ConnectionsIcon from "../../assets/SVG/SidebarIcons/ConnectionsIcon";

import ResourcesIcon from "../../assets/SVG/SidebarIcons/ResourcesIcon";
import ProgramsIcon from "../../assets/SVG/SidebarIcons/ProgramsIcon";

import ShopIcon from "../../assets/SVG/SidebarIcons/ShopIcon";
import EventIcon from "../../assets/SVG/SidebarIcons/EventIcon";

const main_menu = [
  {
    id: 1,
    title: "Admin",
    icon_image: "/images/SidebarIcons/SidebarAdminIcon.svg",
    iconComponent: AdminIcon,
    iconKey: "stroke",
    url: "/admin",
    isSelf: true,
  },
  {
    id: 2,
    title: "Forum",
    icon_image: "/images/SidebarIcons/SidebarDashboardIcon.svg",
    iconComponent: DashboardIcon,
    iconKey: "stroke",
    url: "/community-forum",
    isSelf: true,
  },
  {
    id: 3,
    title: "Chat",
    icon_image: "/images/SidebarIcons/SidebarChatIcon.svg",
    iconComponent: ChatIcon,
    iconKey: "stroke",
    url: "/chat",
    isSelf: true,
  },
  {
    id: 4,
    title: "Members",
    icon_image: "/images/SidebarIcons/SidebarContactsIcon.svg",
    iconComponent: ConnectionsIcon,
    iconKey: "stroke",
    url: "/connections",
    isSelf: true,
  },
  {
    id: 5,
    title: "Programs",
    icon_image: "/images/SidebarIcons/SidebarIntegrationsIcon.svg",
    iconComponent: ResourcesIcon,
    iconKey: "stroke",
    url: "/programs",
    isSelf: true,
  },
  {
    id: 4,
    title: "Events",
    icon_image: "/images/SidebarIcons/SidebarContactsIcon.svg",
    iconComponent: EventIcon,
    iconKey: "stroke",
    url: "/events",
    isSelf: true,
    disabled: true,
  },
  {
    id: 5,
    title: "Shop",
    icon_image: "/images/SidebarIcons/SidebarIntegrationsIcon.svg",
    iconComponent: ShopIcon,
    iconKey: "stroke",
    url: "/shop",
    isSelf: true,
    disabled: true,
  },
  // {
  //   id: 6,
  //   title: "Resources",
  //   icon_image: "/images/SidebarIcons/SidebarSupportIcon.svg",
  //   iconComponent: ProgramsIcon,
  //   iconKey: "stroke",
  //   url: "/resources",
  //   isSelf: true,
  // },
  // {
  //   id: 7,
  //   title: "Account",
  //   icon_image: "/images/SidebarIcons/SidebarAccountIcon.svg",
  //   iconKey: "stroke",
  //   url: "/settings",
  //   isSelf: true,
  // },
];

export default main_menu;

// {
//   id: 2,
//   title: "Campaigns",
//   icon_image: "/images/SidebarIcons/CampaignsIcon.svg",
//   iconKey: "stroke",
//   url: "/campaigns",
//   isSelf: true,
// },
// {
//   id: 7,
//   title: "Integrations",
//   icon_image: "/images/SidebarIcons/SidebarIntegrationsIcon.svg",
//   iconKey: "stroke",
//   url: "/integrations",
//   isSelf: true,
// },
// {
//   id: 7,
//   title: "Chatbot Testing",
//   icon_image: "/images/SidebarIcons/ChatbotTestingIcon.svg",
//   iconKey: "stroke",
//   url: "/chatbottesting",
//   isSelf: true,
// },
// {
//   id: 8,
//   title: "Feedback",
//   icon_image: "/images/SidebarIcons/SidebarSupportIcon.svg",
//   iconKey: "stroke",
//   url: "/feedback",
//   isSelf: true,
// },
