import React from "react";
import CircularLoader from "../components/Portal/universal_components/CircularLoader/CircularLoader";

const LoadingPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularLoader />
    </div>
  );
};

export default LoadingPage;
