// useSubdomainNavigate.js
import { useNavigate } from "react-router-dom";
import { extractSubdomain } from "./extractSubdomain";

export const useSubdomainNavigate = () => {
  const navigate = useNavigate();
  const domain = extractSubdomain();

  return (path) => {
    const fullPath = domain ? `/${domain}${path}` : path;
    navigate(fullPath);
  };
};
