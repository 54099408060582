import React from "react";

const ResourcesIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    id="puzzle"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25.365 25.365"
  >
    <g id="Group_4573" data-name="Group 4573" transform="translate(13.425)">
      <g id="Group_4572" data-name="Group 4572">
        <path
          id="Path_2071"
          data-name="Path 2071"
          d="M280.71,0H271V3.859a2.972,2.972,0,1,1,0,5.758v2.323h3.537a.75.75,0,0,1,.556,1.237,1.468,1.468,0,0,0-.377.992,1.486,1.486,0,0,0,2.972,0,1.468,1.468,0,0,0-.377-.992.744.744,0,0,1,.556-1.237h5.073V2.229A2.232,2.232,0,0,0,280.71,0Z"
          transform="translate(-271)"
          fill={color}
        />
      </g>
    </g>
    <g id="Group_4575" data-name="Group 4575">
      <g id="Group_4574" data-name="Group 4574">
        <path
          id="Path_2072"
          data-name="Path 2072"
          d="M14.168,5.251a1.49,1.49,0,0,0-.993.377.743.743,0,0,1-1.237-.555V0H2.229A2.232,2.232,0,0,0,0,2.229v9.71H3.859a2.972,2.972,0,1,1,5.756,0h2.324V8.4a.743.743,0,0,1,1.237-.555,1.487,1.487,0,1,0,.993-2.6Z"
          fill={color}
        />
      </g>
    </g>
    <g id="Group_4577" data-name="Group 4577" transform="translate(0 9.71)">
      <g id="Group_4576" data-name="Group 4576">
        <path
          id="Path_2073"
          data-name="Path 2073"
          d="M11.2,201.945a2.964,2.964,0,0,1,.743.094v-2.324H8.4a.744.744,0,0,1-.556-1.237,1.468,1.468,0,0,0,.377-.992,1.486,1.486,0,1,0-2.972,0,1.468,1.468,0,0,0,.377.992.744.744,0,0,1-.556,1.237H0v9.71a2.232,2.232,0,0,0,2.229,2.229h9.71V207.8a2.972,2.972,0,1,1-.743-5.85Z"
          fill={color}
          transform="translate(0 -196)"
        />
      </g>
    </g>
    <g
      id="Group_4579"
      data-name="Group 4579"
      transform="translate(9.71 13.425)"
    >
      <g id="Group_4578" data-name="Group 4578">
        <path
          id="Path_2074"
          data-name="Path 2074"
          d="M207.8,271a2.978,2.978,0,1,1-5.745,0h-2.335v3.537a.744.744,0,0,1-1.237.556,1.486,1.486,0,1,0,0,2.218.744.744,0,0,1,1.237.556v5.073h9.71a2.232,2.232,0,0,0,2.229-2.229V271Z"
          transform="translate(-196 -271)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default ResourcesIcon;
