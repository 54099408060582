import React from "react";
import { useEffect } from "react";
import styles from "./programs.module.css";
import { useState, useContext } from "react";
import SearchbarHeader from "../../UniversalComponents/SearchbarHeader/SearchbarHeader";
import LinkIcon from "../../../assets/SVG/LinkIcon";
import { useNavigate } from "react-router";
import { FeedContext } from "../../../context/FeedContext";
import { CompanyContext } from "../../../context/CompanyContext";
import colorAlphaConverter from "../../../utils/colorAlphaConverter";
import CheckmarkIcon from "../../../assets/SVG/CheckmarkIcon";

const ProgramsPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { companyData } = useContext(CompanyContext);
  const [selectedGuideline, setSelectedGuideline] = useState(null);
  // const { fineTunedModels } = useContext(ChatbotTuningContext);
  const [searchTerm, setSearchTerm] = useState("");

  const { programs, setPrograms } = useContext(FeedContext);

  function handleModalNav(selectedGuideline) {
    setSelectedGuideline(selectedGuideline);
    setIsEditing(!isEditing);
  }

  function back() {
    setIsEditing(!isEditing);
    setSelectedGuideline(null);
  }

  const primaryColor = colorAlphaConverter(companyData.primary_color, 1);

  const primaryQuarterColor = colorAlphaConverter(
    companyData.primary_color,
    0.25
  );

  const primaryColorHover = colorAlphaConverter(companyData.primary_color, 0.1);

  const sidebarBgColor = colorAlphaConverter(companyData.primary_color, 0.1);

  const lightBackground = colorAlphaConverter(companyData.primary_color, 0.05);

  const menuItemHoverBgColor = colorAlphaConverter(
    companyData.primary_color,
    0.04
  );
  const menuItemHoverColor = colorAlphaConverter(
    companyData.primary_color,
    0.7
  );
  const menuItemSelectedBgColor = colorAlphaConverter(
    companyData.primary_color,
    0.1
  );
  const menuItemSelectedColor = colorAlphaConverter(
    companyData.primary_color,
    1
  );

  return (
    <div
      className={styles.main_layout}
      style={{
        "--primary-color": primaryColor,
        "--primary-quarter-color": primaryQuarterColor,
        "--primary-color-hover": primaryColorHover,
        "--sidebar-bg-color": sidebarBgColor,
        "--menu-item-hover-bg-color": menuItemHoverBgColor,
        "--menu-item-hover-color": menuItemHoverColor,
        "--menu-item-selected-bg-color": menuItemSelectedBgColor,
        "--menu-item-selected-color": menuItemSelectedColor,
        "--light-background": lightBackground,
      }}
    >
      {!isEditing ? (
        <p className={styles.page_title_margin}>Manage Programs</p>
      ) : (
        <div className={styles.title_area_create}>
          <div className={styles.back_button_small} onClick={back}>
            <img
              className={styles.back_icon_opacity}
              src={"/images/BackIcon.png"}
              alt="back"
            ></img>
          </div>
          <p className={styles.page_title}>Create Program</p>
        </div>
      )}
      <div className={styles.edit_guidelinees_modal}>
        {!isEditing ? (
          <>
            <SearchbarHeader
              type="AdminUsers"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              create={false}
              createFunction={handleModalNav}
            />
            <div className={styles.divider}></div>

            {programs && programs.length !== 0 && (
              <div className={styles.guideline_layout}>
                {programs.map((program, index) => {
                  return <Program program={program} index={index} />;
                })}
              </div>
            )}
            <div className={styles.bottom_section_divider}></div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

function Program({ program, index }) {
  const { companyData } = useContext(CompanyContext);
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);

  const onSubscribe = () => {
    setSubscribed(!subscribed);
  };

  return (
    <div className={styles.program_layout} key={index}>
      <div className={styles.model_information_top}>
        <div className={styles.model_information}>
          <div className={styles.model_info_section}>
            <p className={styles.program_title}>{program.title}</p>
            <p className={styles.program_type_title}>{program.program_type}</p>
            <p className={styles.program_description_text}>
              {program.description}
            </p>
          </div>
        </div>
        <div className={styles.top_left}>
          {program.featured && (
            <div className={styles.status_box_featured}>Featured</div>
          )}
        </div>
      </div>
      <div className={styles.model_information_bottom}>
        {subscribed ? (
          <div className={styles.currently_subscribed} onClick={onSubscribe}>
            Subscribed
            <CheckmarkIcon />
            {/* Assuming CheckmarkIcon is correctly imported */}
          </div>
        ) : (
          <div className={styles.subscribe} onClick={onSubscribe}>
            Subscribe
          </div>
        )}
        <div className={styles.view_information_buttons}>
          <div className={styles.learn_more}>
            Learn More
            <LinkIcon color={companyData.primary_color} />{" "}
            {/* Assuming LinkIcon is correctly imported */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramsPage;
