export default function formatUnixTimestamp(unixTimestamp) {
  const date = new Date(unixTimestamp);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes().toString().padStart(2, "0");

  // Determine AM or PM suffix
  const ampm = hour >= 12 ? "PM" : "AM";
  // Convert 24-hour time to 12-hour format
  const formattedHour = hour % 12 || 12; // Convert 0 (midnight) to 12

  return `${month} ${day} at ${formattedHour}:${minute} ${ampm}`;
}
