import React from "react";

const ConnectionsIcon = ({ width = 20, height = 20, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 33.485 33.485"
  >
    <path
      id="Icon_ionic-ios-contacts"
      data-name="Icon ionic-ios-contacts"
      d="M20.118,3.375A16.743,16.743,0,1,0,36.86,20.118,16.74,16.74,0,0,0,20.118,3.375ZM31.652,24.617c-.652-.209-1.908-.274-2.375-.837-.233-.282-.08-1.336-.08-1.336a5.079,5.079,0,0,0,2.5-.491c.314-.185.169-.217.008-.491-.877-1.473-.483-3.34-.523-4.958a4.107,4.107,0,0,0-1.61-3.574,5.128,5.128,0,0,0-2.527-.555A5.652,5.652,0,0,0,25,12.7c-3.413,1.368-1.4,5.892-2.568,8.484-.2.435-.491.588.016.845a6.225,6.225,0,0,0,2.326.507s.024,1.014,0,1.119c-.089.394-.95.668-1.272.757a10.6,10.6,0,0,0-1.32.459.32.32,0,0,0,.064.612,6.223,6.223,0,0,1,.926.233,8.751,8.751,0,0,1,3.7,2.012,5.493,5.493,0,0,1,1.61,3.59,1,1,0,0,1-.443.926l-.459.29a.23.23,0,0,1-.346-.193h0A5.485,5.485,0,0,0,23.555,27.1c-1.087-.378-2.262-.4-3.349-.781-.33-.113-.982-.25-1.119-.628a4.563,4.563,0,0,1-.153-1.191q-.024-.459-.024-.918c0-.2.515-.628.628-.813a6.12,6.12,0,0,0,.555-2.519c.7.193.789-1.1.91-1.5.089-.274.4-1.682-.209-1.9a3.385,3.385,0,0,0,.338-1.183,7.111,7.111,0,0,0-.089-3.163A4.984,4.984,0,0,0,16.27,9.171a5.118,5.118,0,0,0-5.111,3.043,6.644,6.644,0,0,0-.225,3.179,3.983,3.983,0,0,0,.378,1.457c-.515.225-.362,1.425-.274,1.715.129.411.241,1.884.974,1.682a11.515,11.515,0,0,0,.314,1.956,2.926,2.926,0,0,0,.66,1.119c.145.161.217.177.209.386a8.037,8.037,0,0,1-.153,1.916c-.161.612-1.505.869-2.045.982a11.864,11.864,0,0,0-3.332.926,14.5,14.5,0,0,1,12.316-21.9A14.545,14.545,0,0,1,30.268,9.774a14.524,14.524,0,0,1,3.18,16.067A3.8,3.8,0,0,0,31.652,24.617Z"
      transform="translate(-3.375 -3.375)"
      fill={color}
    />
  </svg>
);

export default ConnectionsIcon;
