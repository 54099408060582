import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { useContext, useEffect } from "react";
import App from "./App";
import axios from "axios";
import NoPageFound from "./pages/NoPageFound";
import { useSubdomainNavigate } from "./utils/subdomainNavigate";

import SignInPage from "./pages/Authentication/SignInPage";
import ProfilePage from "./pages/Portal/ProfilePage";
import NoNexusFound from "./pages/NoNexusFound";

import AdminPage from "./pages/Admin/AdminPage";
import DashboardPage from "./pages/Portal/DashboardPage";
import InboxPage from "./pages/Portal/InboxPage";
import ProgramsPage from "./pages/Portal/ProgramsPage";
import ResourcesPage from "./pages/Portal/ResourcesPage";
import SettingsPage from "./pages/Portal/SettingsPage";
import { AuthContext } from "./context/AuthContext";
import ConnectionsPage from "./pages/Portal/ConnectionsPage";

import CommunityPage from "./pages/Portal/CommunityPage";
import LoadingPage from "./pages/LoadingPage";

import FeedbackPage from "./pages/Portal/FeedbackPage";
import { CompanyContext } from "./context/CompanyContext";

import { extractSubdomain } from "./utils/extractSubdomain";

import { useNavigate } from "react-router";

import SignInParentPage from "./pages/ParentAuth/SignInPage";
import RequestAccessComponent from "./components/ParentAuthentication/RequestAccess/RequestAccess";

const extractDomainNameFromUrl = () => {
  const path = window.location.pathname.split("/");
  const domainName = path.length > 1 ? path[1] : null;
  return domainName;
};

// function PrivateRoute({ children }) {
//   const subdomainNavigate = useSubdomainNavigate();

//   const isAuthenticated = () => {
//     const storedLogins = JSON.parse(localStorage.getItem("logins")) || [];

//     const currentLoginData = storedLogins.find(
//       (login) => login.domain === domainName
//     );

//     return !!currentLoginData;
//   };

//   return isAuthenticated ? children :   subdomainNavigate("/signin") />;
// }

function PrivateRoute({ children }) {
  const subdomainNavigate = useSubdomainNavigate();

  const domainName = extractSubdomain();

  const isAuthenticated = () => {
    const storedLogins = JSON.parse(localStorage.getItem("logins")) || [];
    const currentLoginData = storedLogins.find(
      (login) => login.domain === domainName
    );
    return !!currentLoginData;
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      subdomainNavigate("/signin");
    }
  }, []);

  return isAuthenticated() ? children : null;
}

const DirectLinkHandler = () => {
  const { companyName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      console.log("No token provided");

      return;
    }

    verifyToken(token, companyName);
  }, [companyName]);

  const verifyToken = async (token, companyName) => {
    try {
      const response = await axios.post(
        "https://nexus-84ef5.uc.r.appspot.com/verify-direct-link",
        { token, companyName }
      );
      console.log("Token verified:", response.data);
      navigate(`/${companyName}/community-forum`);
    } catch (error) {
      console.error("Failed to verify token:", error);
      // navigate(`/${companyName}/signin`);
    }
  };

  return <div>Loading...</div>;
};

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App childComp={<SignInParentPage />} />} />
          <Route
            path="/login"
            element={<App childComp={<SignInParentPage />} />}
          />
          <Route
            path="/request-access"
            element={<App childComp={<RequestAccessComponent />} />}
          />
          <Route
            path="/:companyName/direct-link"
            element={
              <PrivateRoute>
                <DirectLinkHandler />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName"
            element={
              <PrivateRoute>
                <App childComp={<SignInPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/signin"
            element={<App childComp={<SignInPage />} />}
          />

          <Route
            path="/no-nexus-found"
            element={
              <PrivateRoute>
                <App childComp={<NoNexusFound />} />
              </PrivateRoute>
            }
          />

          {/* <Route path="/signup" element={<App childComp={<SignUpPage />} />} /> */}

          <Route
            path="/:companyName/admin"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/admin/manage-users"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/admin/manage-announcements"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/admin/manage-programs"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/:companyName/admin/manage-resources"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/:companyName/admin/admin-settings"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/profile"
            element={
              <PrivateRoute>
                <App childComp={<ProfilePage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/profile/:id"
            element={
              <PrivateRoute>
                <App childComp={<ProfilePage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/:companyName/community-forum"
            element={
              <PrivateRoute>
                <App childComp={<CommunityPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/:companyName/chat"
            element={
              <PrivateRoute>
                <App childComp={<InboxPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/:companyName/connections"
            element={
              <PrivateRoute>
                <App childComp={<ConnectionsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/programs"
            element={
              <PrivateRoute>
                <App childComp={<ProgramsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/resources"
            element={
              <PrivateRoute>
                <App childComp={<ResourcesPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/:companyName/feedback"
            element={<App childComp={<FeedbackPage />} />}
          />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
