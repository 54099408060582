import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import InboxComponent from "../../components/Portal/InboxComponents/InboxComponent";

const InboxPage = () => {
  return (
    <>
      <Sidebar childComponent={<InboxComponent />} activeText="Chat" />
    </>
  );
};

export default InboxPage;
