import React from "react";
import { useEffect, useRef } from "react";
import styles from "./programs.module.css";
import { useState, useContext } from "react";
import { DynamicFormField } from "../../UniversalComponents/DynamicFormField/DynamicFormField";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import { toast } from "react-toastify";

export default function CreateProgram(props) {
  const [deletePopup, setDeletePopup] = useState(false);

  const [file, setFile] = useState(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);

  const [program, setProgram] = useState(props.selectedProgram);

  const updateField = (field, value) => {
    setProgram({ ...program, [field]: value });
  };
  //https://cosecapital.com/
  let canCreate =
    program?.title && program?.description && program?.program_type;

  const [isCreating, setIsCreating] = useState(false);

  const programCategories = [
    "Workshops & Seminars",
    "Conferences & Summits",
    "Webinars & Online Events",
    "Community & Networking",
    "Training & Development",
    "Competitions & Challenges",
    "Exhibitions & Fairs",
    "Fundraisers & Campaigns",
    "Volunteering Opportunities",
    "Publications & Articles",
    "Tools & Applications",
    "Other",
  ];

  async function handleSave() {
    setIsCreating(true);
    try {
      await props.saveProgram(program);
      props.back();
    } catch (error) {
      console.error("Error saving program:", error);
    }
    setIsCreating(false);
  }

  return (
    <div className={styles.edit_guideline_modal}>
      <div className={styles.back_box}></div>
      <div className={styles.form_field_container_margin}>
        <DynamicFormField
          title="Program Title"
          content={program?.title}
          onValueChange={(value) => updateField("title", value)}
        />
        <DynamicFormField
          title="Program Category / Type"
          dropdown={true}
          dropDownData={programCategories}
          content={program?.program_type}
          onValueChange={(value) => updateField("program_type", value)}
        />
      </div>
      <div className={styles.section_divider}></div>
      <div className={styles.full_section}>
        <DynamicFormField
          title="Program Description"
          paragraph={true}
          content={program?.description}
          onValueChange={(value) => updateField("description", value)}
        />
      </div>
      <div className={styles.section_divider}></div>
      <div className={styles.full_section}>
        <DynamicFormField
          title="Program Link (Optional)"
          content={program?.link}
          onValueChange={(value) => updateField("link", value)}
        />
      </div>
      {/* <div className={styles.section_divider}></div> */}

      <div className={styles.section_divider}></div>
      <div className={styles.modal_footer}>
        <div className={styles.buttons}>
          <div className={styles.cancel_button} onClick={props.back}>
            Cancel
          </div>
          {isCreating ? (
            <div className={styles.save_button}>
              <CircularLoader size="25px" color="rgba(0, 61, 130, 1)" />
            </div>
          ) : (
            <>
              {canCreate ? (
                <div className={styles.save_button} onClick={handleSave}>
                  Create Program
                </div>
              ) : (
                <div className={styles.save_button_disabled}>
                  Create Program
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  function UploadValidation() {
    return (
      <div>
        <div>
          {isLoadingDocument ? (
            <div className={styles.document_layout}>
              <CircularLoader />
            </div>
          ) : (
            <div className={styles.document_layout}>
              {!file ? (
                <div className={styles.drag_image}>
                  <div>
                    <div className={styles.audioDropzone}>
                      <img
                        src="/fileUpload.png"
                        alt="upload"
                        className={styles.drag_track_image}
                      ></img>
                      <p className={styles.drag_track_text}>
                        Drag or Upload Document
                      </p>
                      <p className={styles.drag_track_text_browse}>
                        Browse Files
                      </p>

                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        disabled={isLoadingDocument}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.chosen_document}>
                  <DisplayDocument
                    file={file}
                    setDeletePopup={(data) => setDeletePopup(data)}
                    desc={"Are you sure you want to delete?"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  function DisplayDocument(props) {
    const [isReferanceHovered, setIsReferanceHovered] = useState(false);
    const { fileName, setDeletePopup, setDeleteName, type, file } = props;

    function openDocument() {
      window.open(file, "_blank").focus();
    }

    return (
      <div
        className={
          isReferanceHovered
            ? `${styles.document_display} ${styles.hovered}`
            : styles.document_display
        }
      >
        <img
          src="/deleteOptionEx.png"
          alt="Check"
          className={styles.delete_icon_corner}
          onMouseEnter={() => setIsReferanceHovered(true)}
          onMouseLeave={() => setIsReferanceHovered(false)}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        />
        <p className={styles.document_file_title} onClick={openDocument}>
          {fileName}
        </p>
      </div>
    );
  }
}
