import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase"; // Import storage separately

import {
  onSnapshot,
  orderBy,
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { CompanyContext } from "./CompanyContext";
import { extractDatabaseReferance } from "../utils/extractDatabaseReferance";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [appLoading, setAppLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [connections, setConnections] = useState([]);
  const [loadingConnections, setLoadingConnections] = useState(true);
  const [companyDatabaseReference, setCompanyDatabaseReference] =
    useState(null);

  const extractDomainNameFromUrl = () => {
    const path = window.location.pathname.split("/");
    const domainName = path.length > 1 ? path[1] : null;
    return domainName;
  };

  const [allUsers, setAllUsers] = useState([]);

  const [unreadMessages, setUnreadMessages] = useState(0);

  const fetchAllUsers = async () => {
    if (!companyDatabaseReference) {
      console.log("Database reference not ready.");
      return;
    }

    try {
      const usersRef = collection(companyDatabaseReference, "Accounts");
      const querySnapshot = await getDocs(usersRef);

      const users = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          users.push({ id: doc.id, ...userData });
        }
      });

      setAllUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const collectionsToMigrate = [
    "artemis",
    "cosecapital",
    "example",
    "lorenzoshouse",
    "nexus",
    "pinkdog101",
    "worldlibertycongress",
    "x",
  ];

  // useEffect(() => {
  //   setAppLoading(true);
  //   console.log("RELOADING");
  //   const domainName = extractDomainNameFromUrl();
  //   const singleDocId = "default";
  //   const docRef = doc(db, domainName, singleDocId);
  //   setCompanyDatabaseReference(docRef);
  //   // Function to extract domain name from URL
  //   // Retrieve the stored logins for all domains
  //   const storedLogins = JSON.parse(localStorage.getItem("logins")) || [];
  //   // Find the user data for the current domain
  //   const currentUserData = storedLogins.find(
  //     (login) => login.domain === domainName
  //   );

  //   if (currentUserData && currentUserData.userData) {
  //     const user = JSON.parse(currentUserData.userData);
  //     setCurrentUser(user);
  //     fetchUserData(user.uid, docRef);
  //   }

  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setCurrentUser(user);
  //       fetchUserData(user.uid);

  //       const updatedLogins = storedLogins.filter(
  //         (login) => login.domain !== domainName
  //       );
  //       updatedLogins.push({
  //         domain: domainName,
  //         userData: JSON.stringify(user),
  //       });
  //       localStorage.setItem("logins", JSON.stringify(updatedLogins));
  //     } else {
  //       setCurrentUser(null);
  //       setProfile(null);

  //       const remainingLogins = storedLogins.filter(
  //         (login) => login.domain !== domainName
  //       );
  //       localStorage.setItem("logins", JSON.stringify(remainingLogins));
  //     }

  //     setAppLoading(false);
  //   });

  //   return () => unsubscribe();
  // }, []);

  const signIn = async (email, password, domain) => {
    try {
      // Sign in with email and password
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const domainName = extractDomainNameFromUrl();

      // Fetch user data
      const userDataFetched = await fetchUserData(credential.user.uid);

      // If userDataFetched is false, throw an error indicating invalid credentials
      if (!userDataFetched) {
        throw new Error("Invalid Credentials for This Nexus App");
      }

      // Set current user and save login data
      setCurrentUser(credential.user);

      // Retrieve existing logins from local storage
      const storedData = JSON.parse(localStorage.getItem("logins")) || [];
      const updatedData = storedData.filter((login) => login.domain !== domain);

      updatedData.push({
        domain: domain,
        userData: JSON.stringify(credential.user),
      });

      localStorage.setItem("logins", JSON.stringify(updatedData));

      return credential;
    } catch (error) {
      // Handle authentication error
      setError(error.message);
      throw error;
    }
  };

  const fetchUserData = async (userId, databaseReference) => {
    try {
      const userDocRef = doc(extractDatabaseReferance(), "Accounts", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setProfile(userData);
        return true; // Data successfully fetched
      } else {
        console.warn("No data found for user:", userId);
        return false; // No data found
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return false; // Error fetching data
    }
  };

  //companyDatabaseReference
  // useEffect(() => {
  //   setAppLoading(true);

  //   const storedUser = localStorage.getItem("user");
  //   if (storedUser) {
  //     const user = JSON.parse(storedUser);
  //     setCurrentUser(user);
  //     fetchUserData(user.uid);
  //     // fetchRequestCount();
  //     // fetchAllUsers();
  //   }

  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setCurrentUser(user);
  //       localStorage.setItem("user", JSON.stringify(user));
  //       fetchUserData(user.uid);
  //       // fetchRequestCount();
  //       // fetchAllUsers();
  //     } else {
  //       setCurrentUser(null);
  //       setProfile(null);
  //       localStorage.removeItem("user");
  //     }

  //     setAppLoading(false);
  //   });

  //   return () => unsubscribe();
  // }, []);

  //https://media.licdn.com/dms/image/C4E03AQFAgayLHxlKoQ/profile-displayphoto-shrink_800_800/0/1520900063159?e=2147483647&v=beta&t=8bpVp2vkJWTGhk1eILKw2vgzlDkn69UaEfxCd5fAlpo

  const createAccount = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).catch(
      (error) => {
        setError(error.message);
      }
    );
  };

  const addWaitlistUser = async (email, name, number, company) => {
    const waitlistRef = collection(companyDatabaseReference, "Waitlist");
    const q = query(waitlistRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      const newDocRef = doc(waitlistRef);
      await setDoc(newDocRef, {
        name: name,
        email: email,
        number: number,
        company: company,
      });

      const [firstName, lastName] = name.split(" ");
      await addBrevoMainContact(email, firstName, lastName);
    }
  };
  const addBrevoMainContact = async (email, firstName, lastName) => {
    try {
      // Create a request payload
      const requestData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
      };

      const firebaseFunctionUrl =
        "https://us-central1-reply-assist.cloudfunctions.net/addContactToBrevo";

      const response = await axios.post(firebaseFunctionUrl, requestData);

      if (response.status === 200) {
        console.log("Firebase Function Response:", response.data);
      } else {
        console.error(
          `Error calling Firebase Function. Status Code: ${
            response.status
          }, Response: ${JSON.stringify(response.data)}`
        );
      }
    } catch (error) {
      if (error.response) {
        console.error(
          `Error calling Firebase Function. Status Code: ${
            error.response.status
          }, Response: ${JSON.stringify(error.response.data)}`
        );
      } else if (error.request) {
        console.error(
          "No response received from Firebase Function.",
          error.request
        );
      } else {
        console.error("Error calling Firebase Function:", error.message);
      }
    }
  };

  const addUserToNewsletter = async (email) => {
    const waitlistRef = collection(companyDatabaseReference, "Waitlist");

    const newDocRef = doc(waitlistRef);

    return setDoc(newDocRef, {
      email: email,
    });
  };

  const updateAccountProfilePicture = async (userId, file) => {
    console.log(userId);
    try {
      const storageRef = ref(storage, `profile_pictures/${userId}`);

      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);
      const userDocRef = doc(
        companyDatabaseReference,
        "Accounts",
        currentUser.uid
      );

      await updateDoc(userDocRef, {
        profilePicture: downloadURL,
      });

      setProfile((prevProfile) => ({
        ...prevProfile,
        profilePicture: downloadURL,
      }));

      console.log("Profile picture updated successfully.");
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  const updateUserProfile = async (userID, updatedProfile) => {
    try {
      const userDocRef = doc(
        companyDatabaseReference,
        "Accounts",
        currentUser.uid
      );
      await updateDoc(userDocRef, updatedProfile);

      setProfile((prevProfile) => ({
        ...prevProfile,
        ...updatedProfile,
      }));

      console.log("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating user profile:", error);
      setError(error.message);
    }
  };

  const fetchProfileData = async (userId) => {
    try {
      const userDocRef = doc(extractDatabaseReferance(), "Accounts", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log(userData);
        return userData;
      } else {
        console.warn("No data found for user:", userId);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [requestCount, setRequestCount] = useState(null);
  const fetchRequestCount = async (userId, status = "PENDING") => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      const currentUserId = storedUser?.uid;

      if (!currentUserId) {
        return;
      }

      const connectionsRef = collection(
        companyDatabaseReference,
        "Connections"
      );
      let q;

      if (status === "PENDING") {
        q = query(
          connectionsRef,
          where("status", "==", "PENDING"),
          where("accounts", "array-contains", currentUserId)
        );
      } else {
        q = query(connectionsRef, where("accounts", "array-contains", userId));
      }

      const querySnapshot = await getDocs(q);
      console.log("SIZE", querySnapshot.size);
      setRequestCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching connection count:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      setProfile(null);
      localStorage.removeItem("user");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        profile,
        createAccount,
        error,
        addWaitlistUser,
        addUserToNewsletter,
        currentUser,
        allUsers,
        updateAccountProfilePicture,
        updateUserProfile,
        requestCount,
        fetchProfileData,
        appLoading,
        logout,
        setUnreadMessages,
        unreadMessages,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
//allUsers
