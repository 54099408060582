import React from "react";
import styles from "./popup.module.css";

export function ManageUserPopup(props) {
  const { openModal, closeModal, actionFunction, desc } = props;

  if (!openModal) {
    return null;
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  return (
    <div open={openModal} onClose={closeModal} className={styles.overlay}>
      <div style={modalStyle} className={styles.modalPaper}>
        <div className={styles.modal_header}></div>
        <div className={styles.modal_body}>
          <div className={styles.modal_icon}>
            <svg
              clip-rule="evenodd"
              fill-rule="evenodd"
              height="200"
              width="200"
              stroke-linejoin="round"
              stroke-miterlimit="1"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_9327831"
            >
              <g id="Add-User1">
                <path d="m10.105 22.3c.21-.482.511-.926.89-1.305.797-.797 1.878-1.245 3.005-1.245h4c1.127 0 2.208.448 3.005 1.245.379.379.68.823.89 1.305.166.379.608.553.988.387.379-.165.553-.608.387-.987-.285-.653-.691-1.253-1.204-1.766-1.078-1.078-2.541-1.684-4.066-1.684-1.3 0-2.7 0-4 0-1.525 0-2.988.606-4.066 1.684-.513.513-.919 1.113-1.204 1.766-.166.379.008.822.387.987.38.166.822-.008.988-.387z"></path>
                <path d="m16 8.25c-3.174 0-5.75 2.576-5.75 5.75s2.576 5.75 5.75 5.75 5.75-2.576 5.75-5.75-2.576-5.75-5.75-5.75zm0 1.5c2.346 0 4.25 1.904 4.25 4.25s-1.904 4.25-4.25 4.25-4.25-1.904-4.25-4.25 1.904-4.25 4.25-4.25z"></path>
                <path d="m26.609 12.25c.415 1.173.641 2.435.641 3.75 0 6.209-5.041 11.25-11.25 11.25s-11.25-5.041-11.25-11.25 5.041-11.25 11.25-11.25c1.315 0 2.577.226 3.75.641.39.138.819-.067.957-.457s-.067-.819-.457-.957c-1.329-.471-2.76-.727-4.25-.727-7.037 0-12.75 5.713-12.75 12.75s5.713 12.75 12.75 12.75 12.75-5.713 12.75-12.75c0-1.49-.256-2.921-.727-4.25-.138-.39-.567-.595-.957-.457s-.595.567-.457.957z"></path>
                <path d="m22 8.25h5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-5c-.414 0-.75.336-.75.75s.336.75.75.75z"></path>
                <path d="m23.75 5v5c0 .414.336.75.75.75s.75-.336.75-.75v-5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"></path>
              </g>
            </svg>
          </div>
          <p className={styles.popup_text}>{desc}</p>
          <div className={styles.input_option}></div>
          <div className={styles.input_option}></div>
          <div className={styles.input_option}></div>
          <div className={styles.popup_buttons}>
            <div
              className={styles.cancel_button}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </div>
            <div
              className={styles.delete_button}
              onClick={(event) => {
                actionFunction(event);
                closeModal();
              }}
            >
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
