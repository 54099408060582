import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./feed.module.css";
import { FeedContext } from "../../../context/FeedContext";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function FeedPageSuggested(props) {
  const { matchedUsers } = useContext(FeedContext);
  const { profile } = useContext(AuthContext);

  const connectedUsers = profile?.connected_users;

  const suggestedUsers = matchedUsers.filter((user) => {
    return user?.uid !== profile?.uid && !connectedUsers?.includes(user?.uid);
  });

  return (
    <div className={styles.match_section}>
      <p className={styles.section_title_match}>
        {/* Care Partner Match Program */}
        {props?.title}
      </p>
      {/* {suggestedUsers.map(
        (user) =>
          user?.uid !== profile?.uid && (
            <SuggestedUser key={user?.uid} {...user} />
          )
      )} */}
    </div>
  );
}

function SuggestedUser(props) {
  const { sendConnectionRequest } = useContext(ChatContext);
  const [connectionStatus, setConnectionStatus] = useState(null);

  const handleConnect = () => {
    sendConnectionRequest(props.id);
    setConnectionStatus("PENDING");
    toast.success(`Connection Request Sent to ${props.firstname}!`);
  };

  const handleRescindConnection = () => {
    setConnectionStatus(null);
  };

  const navigate = useNavigate();

  function goToProfile() {
    navigate(`/profile/${props.id}`);
  }

  function ConnectButton() {
    return (
      <div className={styles.connect_button_background} onClick={handleConnect}>
        <p className={styles.connect_text}>Connect</p>
        <img className={styles.connect_icon} src="/connectIcon.png" />
      </div>
    );
  }

  function ConnectButtonSent() {
    return (
      <div
        className={styles.connect_button_background_sent}
        onClick={handleRescindConnection}
      >
        <p className={styles.sent_text}>Sent</p>
      </div>
    );
  }

  return (
    <div className={styles.suggested_match}>
      <div className={styles.post_top_left}>
        <img
          className={styles.profile_match_image}
          onClick={goToProfile}
          src={props.profilePicture}
        ></img>
        <div className={styles.match_text}>
          <p className={styles.match_name} onClick={goToProfile}>
            {props.firstname} {props.lastname}
          </p>
          <p className={styles.match_subtitle}>{props.position}</p>
        </div>
      </div>
      {connectionStatus === null ? <ConnectButton /> : <ConnectButtonSent />}
    </div>
  );
}

export default FeedPageSuggested;
