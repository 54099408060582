import React from "react";

const EventIcon = ({ width = 20, height = 20, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="fi_1535952"
    x="0px"
    y="0px"
    viewBox="0 0 511.746 511.746"
    width={width}
    height={height}
  >
    <g>
      <path d="M0,502.358h511.746V159.396H0V502.358z M379.411,209.402h52.942v52.941h-52.942V209.402z M379.411,299.407h52.942v52.942   h-52.942V299.407z M379.411,389.413h52.942v52.941h-52.942V389.413z M279.405,209.402h52.941v52.941h-52.941V209.402z    M279.405,299.407h52.941v52.942h-52.941V299.407z M279.405,389.413h52.941v52.941h-52.941V389.413z M179.398,209.402h52.942   v52.941h-52.942V209.402z M179.398,299.407h52.942v52.942h-52.942V299.407z M179.398,389.413h52.942v52.941h-52.942V389.413z    M79.393,209.402h52.941v52.941H79.393V209.402z M79.393,299.407h52.941v52.942H79.393V299.407z M132.334,389.413v52.941H79.393   v-52.941H132.334z"></path>
      <polygon points="511.746,39.391 421.74,39.391 421.74,99.394 391.74,99.394 391.74,39.391 120.006,39.391 120.006,99.394    90.006,99.394 90.006,39.391 0,39.391 0,129.396 511.746,129.396  "></polygon>
      <rect x="90.006" y="9.388" width="30" height="30"></rect>
      <rect x="391.74" y="9.388" width="30" height="30"></rect>
    </g>
  </svg>
);

export default EventIcon;
