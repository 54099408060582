import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./connections.module.css";
import ConnectionLayout from "./ConnectionLayout";
import { ChatContext } from "../../../context/ChatContext";
import { CompanyContext } from "../../../context/CompanyContext";
import SearchbarHeader from "../../UniversalComponents/SearchbarHeader/SearchbarHeader";

export default function ConnectionComponent() {
  const [activeTab, setActiveTab] = useState("Connected");
  // const tabs = ["Connected", "Requests", "Pending"];
  const tabs = ["Connected"];
  const sortByDropdownOptions = ["Date Connected", "Relationship", "Age"];
  const { fetchConnections, setConnections, allUsers } =
    useContext(ChatContext);
  const { companyData } = useContext(CompanyContext);
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  async function handleFetchConnections() {
    const connections = await fetchConnections(activeTab);
    console.log(connections);
    setConnections(connections);
  }

  const [searchTerm, setSearchTerm] = useState("");

  // useEffect(() => {
  //   const tabEl = [...tabsRef.current.children].find(
  //     (child) => child.textContent === activeTab
  //   );
  //   const { offsetLeft, offsetWidth } = tabEl;
  //   underlineRef.current.style.left = `${offsetLeft}px`;
  //   underlineRef.current.style.width = `${offsetWidth}px`;
  //   handleFetchConnections();
  // }, [activeTab]);

  return (
    <div className={styles.main_layout}>
      <div className={styles.creator_search_component}>
        <p className={styles.page_title}>{companyData.company_name} Members</p>
        <div className={styles.search_layout}>
          <SearchbarHeader
            type="EditChatbot"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
      </div>

      {/* <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div> */}
      <div className={styles.tab_layout}>
        <ConnectionLayout activeTab={activeTab} />
      </div>
    </div>
  );
}
