import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";

const RequestAccessComponent = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  const [loading, setLoading] = useState("");

  const [error, setError] = useState(null);

  function handleSignUp() {
    navigate("/sign-up");
  }

  function handleForgotPassword() {
    navigate("/forgot-password");
  }

  const handleSubmit = async () => {
    // try {
    //   setLoading(true);
    //   await signIn(email, password);
    //   navigate("/social-search");
    // } catch (error) {
    //   console.error("Sign-in error:", error);
    //   setError("Invalid Login Credentials");
    // } finally {
    //   setLoading(false);
    // }
  };

  const canContinue = email !== "" && name !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.sidebar}>
        <img alt="logo" src="/NexusLogo.png" className={styles.logo_icon}></img>
        <p className={styles.page_title}>Hey there 👋</p>
        <p className={styles.page_subtitle}>
          Request early access to Nexus below
        </p>
        <p className={styles.login_input_titles}>Full Name</p>
        <div className={styles.email_input}>
          <input
            onChange={(event) => setEmail(event.target.value)}
            className={styles.input}
          ></input>
        </div>
        <p className={styles.login_input_titles}>Email Address</p>
        <div className={styles.email_input}>
          <input
            onChange={(event) => setEmail(event.target.value)}
            className={styles.input}
          ></input>
        </div>

        <p className={styles.login_input_titles}>Company (optional)</p>
        <div className={styles.email_input}>
          <input
            onChange={(event) => setEmail(event.target.value)}
            className={styles.input}
          ></input>
        </div>
        <div className={styles.spacer}></div>
        {error && <p className={styles.error_text}>{error}</p>}
        {canContinue ? (
          <div onClick={handleSubmit} className={styles.page_button}>
            {loading ? (
              <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
            ) : (
              <p className={styles.join_button_text}>Request Access</p>
            )}
          </div>
        ) : (
          <div className={styles.page_button_inactive}>
            <p className={styles.join_button_text}>Request Access</p>
          </div>
        )}
      </div>
      <div className={styles.right_side_sign_in}>
        <img className={styles.blue_background} src="/abstractbg.jpeg"></img>
        {/* <img className={styles.preview_ss} src="/SignInSS.png"></img> */}
      </div>
    </div>
  );
};

export default RequestAccessComponent;
