import React from "react";

const DashboardIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <path
      id="Icon_material-dashboard"
      data-name="Icon material-dashboard"
      d="M4.5,21.167H17.833V4.5H4.5ZM4.5,34.5H17.833v-10H4.5Zm16.667,0H34.5V17.833H21.167Zm0-30v10H34.5V4.5Z"
      transform="translate(-4.5 -4.5)"
      fill={color}
    />
  </svg>
);

export default DashboardIcon;
