import React, { useContext, useState } from "react";
import Drawer from "@material-ui/core/Drawer";

import Navbar from "../PortalNavbar/Navbar";

import { useMediaQuery } from "@material-ui/core";

import { useNavigate } from "react-router";

import classes from "./sidebar.module.css";

import main_menu from "./main_menu";

import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { useSubdomainNavigate } from "../../utils/subdomainNavigate";
import colorAlphaConverter from "../../utils/colorAlphaConverter";
import LockIcon from "../../assets/SVG/LockIcon";

export function Sidebar(props) {
  //requestCount
  const subdomainNavigate = useSubdomainNavigate();

  const { unreadMessages } = useContext(AuthContext);
  const { companyData } = useContext(CompanyContext);

  const { activeText, activeSubText } = props;
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const [show, setShow] = React.useState(true);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  React.useEffect(() => {}, [isDesktop]);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const navigateTo = (url, isSelf) => {
    if (isSelf) {
      subdomainNavigate(url);
    } else {
      return;
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const getPrimaryColorWithOpacity = (opacity) => {
    if (companyData && companyData.primary_color) {
      const rgbValues = companyData.primary_color.match(/\d+/g);
      if (rgbValues && rgbValues.length === 3) {
        const [r, g, b] = rgbValues;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }
    }
    return "rgba(0, 0, 0, 0)";
  };

  const primary_color = colorAlphaConverter(companyData.primary_color, 1);

  const sidebarItemBackgroundHovered = colorAlphaConverter(
    companyData.primary_color,
    0.2
  );

  const sidebarTextColorSelected = colorAlphaConverter(
    companyData.primary_color,
    1
  );
  //  ? getPrimaryColorWithOpacity(0.1)
  //var(--sidebar-text-color-selected);
  return (
    <div className={classes.root}>
      <Navbar open={openDrawer} admin={props.admin} setOpen={setOpenDrawer} />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{
          "--color-scheme": primary_color,
          "--sidebar-item-background-hovered": sidebarItemBackgroundHovered,
          "--sidebar-text-color-selected": sidebarTextColorSelected,
        }}
        anchor="left"
      >
        <div className={classes.navbar_sidebar_extension}>
          <img
            className={classes.sidebar_logo}
            src={companyData.logo_image}
            alt="logo"
          ></img>
        </div>
        {main_menu.map((text, index) => {
          const iconColor =
            activeText === text.title
              ? companyData.primary_color
              : "rgba(0, 0, 0, 0.9)";

          const sidebarItem = text.disabled
            ? classes.sidebar_tab_item_disabled
            : classes.sidebar_tab_item;

          return (
            <div className={sidebarItem}>
              <div
                className={`${classes.list_item_content} ${
                  activeText === text.title
                    ? classes.active_list_item_content
                    : ""
                }`}
                onClick={(e) => navigateTo(text.url, text.isSelf)}
                onMouseEnter={() => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(null)}
                style={{
                  backgroundColor:
                    activeText === text.title || activeIndex === index
                      ? getPrimaryColorWithOpacity(0.1)
                      : "transparent",
                  color:
                    activeText === text.title || activeIndex === index
                      ? getPrimaryColorWithOpacity(1)
                      : "rgba(255, 255, 255, 0.5)",
                }}
              >
                {/* Child elements */}
                <div className={classes.sidebar_icon}>
                  <text.iconComponent
                    width={17}
                    height={17}
                    color={iconColor}
                  />
                </div>
                <p
                  className={
                    activeText === text.title
                      ? classes.active_sidebar_text
                      : activeIndex === index
                      ? classes.hovered_sidebar_text
                      : classes.sidebar_text
                  }
                >
                  {text.title}
                </p>
              </div>
              {text.disabled && (
                <div className={classes.locked_icon}>
                  <LockIcon />
                </div>
              )}
              {text.title === "Inbox" && (
                <>
                  {unreadMessages !== 0 && (
                    <div className={classes.sidebar_notification}>
                      {unreadMessages}
                    </div>
                  )}
                </>
              )}

              {/* {text.title === "My Connections" && (
                <div className={classes.sidebar_notification}>
                  {requestCount}
                </div>
              )} */}
            </div>
          );
        })}

        <div className={classes.sidebar_bottom_layout}>
          <div className={classes.sidebar_bottom_options}>
            <div className={classes.iconWrapper}>
              <a
                href="https://www.instagram.com/lorenzos_house/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.social_icon}
                  src="/GrayscaleSocialMediaLogos/instagram.png"
                  alt="icon"
                ></img>
                {/* <InstaIcon /> */}
              </a>
              <a
                href="https://www.facebook.com/lorenzoshouseofficial/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.social_icon}
                  src="/GrayscaleSocialMediaLogos/facebook.png"
                  alt="icon"
                ></img>
                {/* <InstaIcon /> */}
              </a>
              <a
                href="https://www.linkedin.com/company/lorenzos-house"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.social_icon}
                  src="/GrayscaleSocialMediaLogos/linkedin.png"
                  alt="icon"
                ></img>
                {/* <InstaIcon /> */}
              </a>

              <a
                href="https://twitter.com/lorenzos_house?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.social_icon}
                  src="/GrayscaleSocialMediaLogos/twitter.png"
                  alt="icon"
                ></img>
              </a>
            </div>
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "flex-end",

                width: "100%",
              }}
            >
              <p
                style={{
                  color: "#AFAFAF",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                ©{new Date().getFullYear()} {companyData.company_name}
              </p>
            </div>
          </div>
        </div>
      </Drawer>

      <main className={`${classes.content}`}>{props.childComponent}</main>
    </div>
  );
}

// function SidebarIcon(props) {
//   return (
//     <div className={classes.sidebar_icon}>
//       <img
//         className={classes.sidebar_icon_image}
//         src={props.sidebar_icon}
//         alt="icon"
//       ></img>
//     </div>
//   );
// }
