import React from "react";

const AdminIcon = ({ width = 20, height = 20, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 33.666 33.675"
  >
    <g id="Icon" transform="translate(-1.25 -1.244)">
      <circle
        id="Ellipse_178"
        data-name="Ellipse 178"
        cx="8.612"
        cy="8.612"
        r="8.612"
        transform="translate(8.688 1.244)"
        fill={color}
      />
      <path
        id="Path_2057"
        data-name="Path 2057"
        d="M19.516,13.938a5.578,5.578,0,1,0,5.579,5.578A5.58,5.58,0,0,0,19.516,13.938Zm0,2.349a3.23,3.23,0,1,1-3.23,3.229A3.232,3.232,0,0,1,19.516,16.287Z"
        transform="translate(7.179 7.183)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2058"
        data-name="Path 2058"
        d="M19.1,16.066V13.424a1.174,1.174,0,0,0-2.349,0v2.643a1.174,1.174,0,1,0,2.349,0Z"
        transform="translate(8.77 6.228)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2059"
        data-name="Path 2059"
        d="M20.743,17.442l1.868-1.87a1.174,1.174,0,1,0-1.66-1.66l-1.87,1.868a1.175,1.175,0,0,0,1.661,1.661Z"
        transform="translate(9.896 6.974)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2060"
        data-name="Path 2060"
        d="M20.736,19.1h2.643a1.174,1.174,0,1,0,0-2.349H20.736a1.174,1.174,0,0,0,0,2.349Z"
        transform="translate(10.362 8.774)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2061"
        data-name="Path 2061"
        d="M19.082,20.743l1.87,1.868a1.174,1.174,0,0,0,1.66-1.66l-1.868-1.87a1.175,1.175,0,0,0-1.661,1.661Z"
        transform="translate(9.896 9.899)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2062"
        data-name="Path 2062"
        d="M16.75,20.736V23.38a1.174,1.174,0,0,0,2.349,0V20.736a1.174,1.174,0,1,0-2.349,0Z"
        transform="translate(8.77 10.365)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2063"
        data-name="Path 2063"
        d="M15.78,19.082l-1.868,1.87a1.174,1.174,0,0,0,1.66,1.66l1.87-1.868a1.175,1.175,0,0,0-1.661-1.661Z"
        transform="translate(6.97 9.899)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2064"
        data-name="Path 2064"
        d="M16.066,16.75H13.424a1.174,1.174,0,0,0,0,2.349h2.642a1.174,1.174,0,1,0,0-2.349Z"
        transform="translate(6.224 8.774)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2065"
        data-name="Path 2065"
        d="M17.442,15.78l-1.87-1.868a1.174,1.174,0,1,0-1.66,1.66l1.868,1.87a1.175,1.175,0,0,0,1.661-1.661Z"
        transform="translate(6.97 6.974)"
        fill-rule="evenodd"
        fill={color}
      />
      <path
        id="Path_2066"
        data-name="Path 2066"
        d="M19.548,26.651a2.742,2.742,0,0,1,.227-3.619l.3-.305h-.431a2.74,2.74,0,1,1,0-5.48h.431l-.3-.305a2.74,2.74,0,0,1-.122-3.744c-.767-.06-1.553-.091-2.353-.091-5.2,0-9.807,1.3-12.666,3.251C2.453,17.844,1.25,19.734,1.25,21.718v2.27a2.662,2.662,0,0,0,2.662,2.662Z"
        transform="translate(0 6.712)"
        fill-rule="evenodd"
        fill={color}
      />
    </g>
  </svg>
);

export default AdminIcon;
