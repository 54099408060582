import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ProfileComponent from "../../components/Portal/ProfilePage/ProfileComponent";

const ProfilePage = () => {
  return (
    <>
      <Sidebar childComponent={<ProfileComponent />} activeText="" />
    </>
  );
};

export default ProfilePage;
