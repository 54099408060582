import React, { useState } from "react";
import { useContext } from "react";
import styles from "./feed.module.css";
import CircularLoader from "../universal_components/CircularLoader/CircularLoader";
import { FeedContext } from "../../../context/FeedContext";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import UserIcon from "../../../assets/SVG/UserIcon";

function NewPostPrompt({ color, profile, isProfilePrompt = false }) {
  const [loading, setLoading] = useState(false);
  const [postCaption, setPostCaption] = useState("");
  const [image, setImage] = useState(null);
  const [isInputActive, setIsInputActive] = useState(false);

  const { handlePost } = useContext(FeedContext);
  // const { profile } = useContext(AuthContext);

  const handlePostCaptionChange = (e) => {
    setPostCaption(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePostButtonClick = async () => {
    setLoading(true);
    await handlePost(postCaption, image, profile);
    toast.success("Posted!");
    setLoading(false);
    setPostCaption("");
    setImage(null);
  };

  const handleInputFocus = () => {
    setIsInputActive(true);
  };

  const handleInputBlur = () => {
    setIsInputActive(false);
  };

  const canPost = postCaption.trim() !== "";

  const main_layout = isProfilePrompt
    ? styles.new_post_prompt
    : styles.new_post_prompt_small;

  return (
    <div
      className={`${main_layout} ${isInputActive ? styles.inputActive : ""}`}
    >
      <div className={styles.post_top}>
        <div className={styles.post_prompt_left}>
          <div className={styles.new_post_profile_image}>
            {profile?.profilePicture ? (
              <img
                className={styles.new_post_profile_image}
                src={profile?.profilePicture}
                alt="Profile"
              />
            ) : (
              <UserIcon color={color} />
            )}
          </div>

          <div className={styles.new_post_text_bubble}>
            <textarea
              className={styles.post_text}
              placeholder="Say something to the community!"
              value={postCaption}
              onChange={handlePostCaptionChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        </div>
        <div className={styles.post_top_right}>
          <div className={styles.post_more_button}></div>
        </div>
      </div>
      <div className={styles.post_middle}>
        {image && (
          <img className={styles.posted_image} src={image} alt="Image" />
        )}
      </div>
      <div className={styles.post_bottom}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="image-upload"
        />
        <label htmlFor="image-upload" className={styles.post_action_item}>
          <img
            className={styles.post_action_item_icon}
            src="/PostUploadImageIcon.png"
            alt="upload"
          />
          <p className={styles.post_action_item_text}>Upload Image</p>
        </label>
        {canPost ? (
          <div className={styles.post_button} onClick={handlePostButtonClick}>
            {loading ? <CircularLoader size={12} /> : "Post"}
          </div>
        ) : (
          <div className={styles.post_button_disabled}>
            {loading ? <CircularLoader size={12} /> : "Post"}
          </div>
        )}
      </div>
    </div>
  );
}

export default NewPostPrompt;
