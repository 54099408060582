import React from "react";
import { useContext } from "react";

import styles from "./feed.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { FeedContext } from "../../../context/FeedContext";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import formatUnixTimestamp from "../../../utils/formatUnixTimestamp";

function FeedSidebar() {
  const { feed, announcements } = useContext(FeedContext);

  return (
    <div className={styles.right_sidebar}>
      <p className={styles.sidebar_title}>Announcements</p>
      {/* <SidebarPromotion /> */}
      {/* <ImpactReportPromotion /> */}
      {announcements.length !== 0 && (
        <div className={styles.announcement_scroll}>
          {announcements.map((announcement, index) => {
            return <Announcement key={index} announcement={announcement} />;
          })}
          <div className={styles.buffer}></div>
        </div>
      )}
    </div>
  );
}

function Announcement({ announcement }) {
  return (
    <div className={styles.promotion_tile}>
      {/* <div className={styles.promotion_top_info}>
        <img
          src="/ImpactReportCover.png"
          className={styles.promotion_image}
        ></img>
      </div> */}
      <div className={styles.no_image_title_text}>
        <p className={styles.promotion_title}>{announcement.subject}</p>
      </div>
      <p className={styles.promotion_description}>
        {announcement.announcement}
      </p>
      <p className={styles.timestamp_announcement}>
        {formatUnixTimestamp(announcement.timestamp)}
      </p>
    </div>
  );
}

export default FeedSidebar;
