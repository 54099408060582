import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./settings.module.css";
import AccountSettings from "./settings_tabs/accountSettings";
import BrandingSettings from "./settings_tabs/BrandingSettings";
import SubscriptionSettings from "./settings_tabs/SubscriptionSettings";
import VisibilitySettings from "./settings_tabs/visibilitySettings";
import NotificationSettings from "./settings_tabs/notificationSettings";
import { AuthContext } from "../../../context/AuthContext";
import { CompanyContext } from "../../../context/CompanyContext";
import colorAlphaConverter from "../../../utils/colorAlphaConverter";

export default function AdminSettings() {
  const { profile } = useContext(AuthContext);
  const { companyData } = useContext(CompanyContext);
  const [activeTab, setActiveTab] = useState("General");
  // const tabs = ["Account", "Manage Visibility", "Notifications"];
  // const tabs = ["General", "Branding", "Subscription", "Integrations"];
  const tabs = ["General", "Branding"];
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  const primary_color = colorAlphaConverter(companyData.primary_color, 1);

  const tabs_underline = colorAlphaConverter(companyData.primary_color, 0.2);

  return (
    <div
      className={styles.accountContainer}
      style={{
        "--primary-color": primary_color,
        "--tabs-underline": tabs_underline,
        "--logo_background": tabs_underline,
      }}
    >
      <p className={styles.settingsTitle}>Account</p>
      <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      <div className={styles.tab_layout}>
        {activeTab === "General" && <AccountSettings profile={profile} />}
        {activeTab === "Branding" && <BrandingSettings profile={profile} />}
        {activeTab === "Subscription" && (
          <SubscriptionSettings profile={profile} />
        )}
        {/* {activeTab === "Notifications" && <NotificationSettings />} */}
      </div>
    </div>
  );
}
