import React from "react";

const ChatIcon = ({ width = 20, height = 20, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 36 31.5"
  >
    <path
      d="M0,0V22.5L4.5,18H9V4.5H22.5V0ZM13.5,9V27h18L36,31.5V9Z"
      fill={color}
    />
  </svg>
);

export default ChatIcon;
