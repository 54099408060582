// import "./chat.css";
import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import styles from "./profile.module.css";
import { ChatContext } from "../../../context/ChatContext";
import { AuthContext } from "../../../context/AuthContext";
import { FeedContext } from "../../../context/FeedContext";
import ProfilePageContent from "./ProfilePageContent";
import CircularLoader from "../universal_components/CircularLoader/CircularLoader";
import { CompanyContext } from "../../../context/CompanyContext";
import colorAlphaConverter from "../../../utils/colorAlphaConverter";
import UserIcon from "../../../assets/SVG/UserIcon";

export default function ProfileComponent() {
  const { profile, fetchProfileData } = useContext(AuthContext);
  const { fetchPostsByUser } = useContext(FeedContext);
  const { companyData } = useContext(CompanyContext);
  const [profileData, setProfileData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoadingUser(true);
    const fetchData = async () => {
      try {
        let data;
        if (id) {
          data = await fetchProfileData(id);
          setLoadingUser(false);
        } else {
          data = profile;
          console.log("PERSONAL PROFILE");
          console.log(profile);
          setLoadingUser(false);
        }

        if (data) {
          const posts = await fetchPostsByUser(data.id);
          data.posts = posts;
          setProfileData(data);
        }
      } catch (error) {
        setLoadingUser(false);
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, [id, profile, fetchProfileData, fetchPostsByUser]);

  const isSelfProfile = profileData?.uid === profile?.uid;

  const profileGradientColor = colorAlphaConverter(
    companyData.primary_color,
    0.25
  );

  const profileGradientColorHalf = colorAlphaConverter(
    companyData.primary_color,
    0.25
  );

  return (
    <div
      className={styles.main_layout}
      style={{
        "--profile-gradient-color": profileGradientColor,
        "--profile-gradient-color-light": profileGradientColorHalf,
      }}
    >
      <div className={styles.cover_image_background}>
        {/* {isSelfProfile && (
          <div className={styles.edit_profile_button}>Edit Account</div>
        )} */}

        <div className={styles.cover_image_shadow_overlay}></div>
        {profileData?.profile_cover_image ? (
          <img className={styles.cover_image} src="/bg3.jpeg" alt="cover"></img>
        ) : (
          <div className={styles.cover_gradient}></div>
        )}
      </div>
      <div className={styles.profile_banner}>
        <div className={styles.profile_pic}>
          {loadingUser ? (
            <CircularLoader size={25} />
          ) : (
            // <img
            //   className={styles.profile_pic_image}
            //   src={
            //     profileData?.profilePicture
            //       ? profileData?.profilePicture
            //       : "/DefaultAccountImage.png"
            //   }
            //   alt="pic"
            // ></img>

            <div className={styles.profile_pic_image}>
              {profileData?.profile_cover_image ? (
                <img
                  className={styles.profile_pic_image}
                  src={profileData?.profile_cover_image}
                  alt="Profile"
                />
              ) : (
                <UserIcon
                  color={companyData.primary_color}
                  width={40}
                  height={40}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.profile_info_section}>
          <div className={styles.profile_info}>
            <p className={styles.profile_info_title}>
              {profileData?.first_name} {profileData?.last_name}
            </p>
            <p className={styles.profile_info_subtitle}>
              {profileData?.position}
            </p>
          </div>
          <div></div>
        </div>
      </div>
      {/* <div className={styles.profile_page_content}></div> */}
      <ProfilePageContent
        profileData={profileData}
        isSelfProfile={isSelfProfile}
        color={companyData.primary_color}
      />
    </div>
  );
}
