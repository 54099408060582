import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import UserIcon from "../../../../assets/SVG/UserIcon";
import { CompanyContext } from "../../../../context/CompanyContext";

export default function AccountSettings(props) {
  const { updateAccountProfilePicture, updateUserProfile } =
    useContext(AuthContext);
  const { companyData } = useContext(CompanyContext);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const fileInputRef = useRef(null);
  const { profile } = props;
  const [editProfile, setEditProfile] = useState({ ...profile });
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setEditProfile({ ...profile });
  }, [profile]);

  const handleEditProfileChange = (e) => {
    const { name, value } = e.target;
    setEditProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProfilePicture(URL.createObjectURL(file));
      updateAccountProfilePicture(profile.id, file);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function handleEditProfile() {
    setIsEditMode(true);
  }

  async function handleSaveProfile() {
    try {
      await updateUserProfile(profile.id, editProfile);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  }

  return (
    <div className={styles.main_layout}>
      {profile && (
        <>
          <div className={styles.section}>
            <div className={styles.profile_edit_section}>
              <div className={styles.profile_top_section}>
                <p className={styles.section_page_title}>Profile</p>
                <div className={styles.proflie_picture_section}>
                  {/* <div className={styles.profile_picture_bg}>
                    <img
                      alt="logo"
                      src={
                        selectedProfilePicture ||
                        profile.profilePicture ||
                        "/DefaultAccountImage.png"
                      }
                      className={styles.profile_picture}
                    ></img>
                  </div> */}
                  <div className={styles.profile_picture_bg}>
                    {selectedProfilePicture || profile.profilePicture ? (
                      <img
                        className={styles.profile_picture_bg}
                        src={selectedProfilePicture || profile.profilePicture}
                        alt="Profile"
                      />
                    ) : (
                      <UserIcon color={companyData.primary_color} width={27} height={27} />
                    )}
                  </div>
                  <div className={styles.update_profile_picture}>
                    <p onClick={handleUploadButtonClick}>
                      Upload Profile Picture
                    </p>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleProfilePictureChange}
                    />
                  </div>
                </div>
              </div>
              {isEditMode ? (
                <div
                  className={styles.save_profile_button}
                  onClick={handleSaveProfile}
                >
                  Save
                </div>
              ) : (
                <div
                  className={styles.edit_profile_button}
                  onClick={handleEditProfile}
                >
                  Edit Profile
                </div>
              )}
            </div>

            <div />
            <div className={styles.profile_section}>
              <div className={styles.profile_picture_section_left}>
                <p className={styles.input_title}>First Name</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.first_name}
                  name="firstname"
                  onChange={handleEditProfileChange}
                />
                <p className={styles.input_title}>Country</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.country}
                  name="country"
                  onChange={handleEditProfileChange}
                />
              </div>
              <div className={styles.profile_picture_section_right}>
                <p className={styles.input_title}>Last Name</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.last_name}
                  name="lastname"
                  onChange={handleEditProfileChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <p className={styles.section_title}>Login Information</p>
            <div className={styles.profile_section}>
              <div className={styles.profile_picture_section_left}>
                <p className={styles.input_title}>Email Address</p>
                <div className={styles.single_line_input}>{profile.email}</div>
              </div>
              <div className={styles.profile_picture_section_right}>
                <p className={styles.input_title}>Password</p>
                <div className={styles.single_line_input}>********</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function AccountInput({ isEditMode, value, name, onChange }) {
  const handleInput = (e) => {
    if (name === "phoneNumber") {
      // Allow only digits
      const nonDigits = /[^0-9]/g;
      if (nonDigits.test(e.data)) {
        e.target.value = e.target.value.replace(nonDigits, "");
      }
    }
  };

  return (
    <>
      {isEditMode ? (
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          onInput={name === "phoneNumber" ? handleInput : null}
          className={styles.single_line_input_editable}
        />
      ) : (
        <div className={styles.single_line_input}>{value}</div>
      )}
    </>
  );
}
