import styles from "./navbar.module.css";
import { useEffect, useState, useRef, useContext } from "react";
import { useSubdomainNavigate } from "../../utils/subdomainNavigate";
import NotificationsSidebar from "../Notifications/Notifications";
import { AuthContext } from "../../context/AuthContext";
import UserIcon from "../../assets/SVG/UserIcon";
import { CompanyContext } from "../../context/CompanyContext";

export default function Navbar(props) {
  const [isEditingGuidelines, setIsEditingGuidelines] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const firebaseLink =
    "https://console.firebase.google.com/u/0/project/lorenzo-s-house/overview";

  const googleCloudPlatformLink =
    "https://console.cloud.google.com/home/dashboard?project=lorenzo-s-house";

  const loggingLink =
    "https://console.cloud.google.com/logs/query;cursorTimestamp=2024-02-10T14:38:46.644831121Z?project=lorenzo-s-house";

  const handleLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const { logout, profile } = useContext(AuthContext);
  const { companyData } = useContext(CompanyContext);

  async function handleLogout() {
    try {
      await logout();
      subdomainNavigate("/signin");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  const subdomainNavigate = useSubdomainNavigate();
  const navigateTo = (url) => {
    return subdomainNavigate(url);
  };

  function switchToUserMode() {
    subdomainNavigate("/dashboard");
  }

  function handleSettings() {
    subdomainNavigate("/settings");
  }

  function handleProfile() {
    subdomainNavigate("/profile");
  }

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();
  const chatbotMenuRef = useRef();
  const toggleAccountMenuRef = useRef();
  const toggleChatbotMenuRef = useRef();

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleNotificationsClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (
      toggleAccountMenuRef.current &&
      toggleAccountMenuRef.current.contains(event.target)
    ) {
      if (showMenu) return;
    }

    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }

    if (
      toggleChatbotMenuRef.current &&
      toggleChatbotMenuRef.current.contains(event.target)
    ) {
      if (isOpen) return;
    }

    if (
      chatbotMenuRef.current &&
      !chatbotMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.portal_navbar}>
      <div className={styles.portal_navbar_sidebar_extension}></div>

      <div className={styles.portal_navbar_content}>
        <div className={styles.portal_navbar_left_content}></div>
        <div className={styles.portal_navbar_right_content}>
          {notificationsOpen && (
            <div className={styles.notifications_sidebar}>
              <NotificationsSidebar />
            </div>
          )}
          <div className={styles.container_button} ref={menuRef}>
            {profile && (
              <div className={styles.account_navbar_item} onClick={toggleMenu}>
                {/* <img
                  src={profile.profilePicture || "/DefaultAccountImage.png"}
                  alt="icon"
                  className={styles.account_pfp}
                ></img> */}

                <div className={styles.account_pfp}>
                  {profile.profilePicture ? (
                    <img
                      className={styles.account_pfp}
                      src={profile.profilePicture}
                      alt="Profile"
                    />
                  ) : (
                    <UserIcon color={companyData.primary_color} />
                  )}
                </div>

                <div className={styles.account_info}>
                  <p className={styles.account_name}>
                    {profile.first_name} {profile.last_name}
                  </p>
                  <p className={styles.account_position}>
                    {profile.admin ? "Admin" : "Member"}
                  </p>
                </div>

                {/* <img
                  src="/DropDownIconBlack.png"
                  alt="dropdown"
                  className={`${styles.dropdown_icon_icon} ${
                    showMenu ? styles.rotate_180 : ""
                  }`}
                ></img> */}
              </div>
            )}
            {showMenu && (
              <div className={styles.menu}>
                <div className={styles.menu_item} onClick={handleProfile}>
                  <img
                    className={styles.account_menu_icon}
                    src="/AccountMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>My Profile</p>
                </div>
                <div className={styles.menu_item} onClick={handleSettings}>
                  <img
                    className={styles.account_menu_icon}
                    src="/SettingsMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Settings</p>
                </div>
                <div className={styles.menu_item} onClick={handleSettings}>
                  <img
                    className={styles.account_menu_icon}
                    src="/HelpMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Help & Support</p>
                </div>
                <div className={styles.menu_divider_box}>
                  <div className={styles.menu_divider_small} />
                </div>
                <div className={styles.menu_item} onClick={handleLogout}>
                  <img
                    className={styles.account_menu_icon}
                    src="/LogoutMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Logout</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
