import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import { CompanyContext } from "../../../../context/CompanyContext";
import ColorPicker from "react-best-gradient-color-picker";

export default function BrandingSettings(props) {
  const { updateAccountProfilePicture, updateUserProfile } =
    useContext(AuthContext);
  const { companyData, setCompanyData, updateNexusLogoImage } =
    useContext(CompanyContext);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const fileInputRef = useRef(null);
  const profile = companyData;
  const [editProfile, setEditProfile] = useState({ ...companyData });
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setEditProfile({ ...profile });
  }, [profile]);

  const handleColorChange = (colorValue) => {
    setCompanyData((prevState) => ({
      ...prevState,
      primary_color: colorValue,
    }));
  };

  const handleEditProfileChange = (e) => {
    const { name, value } = e.target;
    setEditProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProfilePicture(URL.createObjectURL(file));
      updateNexusLogoImage(file);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function handleEditProfile() {
    setIsEditMode(true);
  }

  async function handleSaveProfile() {
    try {
      await updateUserProfile(profile.id, editProfile);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  }

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      //   updateAccountProfilePicture(file);
    }
  };

  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={styles.brand_tab_layout}>
      {profile && (
        <>
          {/* <div className={styles.section}>
            <div />
            <div className={styles.profile_section}>
              <div className={styles.profile_picture_section_left}>
                <p className={styles.input_title}>First Name</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.firstname}
                  name="firstname"
                  onChange={handleEditProfileChange}
                />
                <p className={styles.input_title}>Country</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.country}
                  name="country"
                  onChange={handleEditProfileChange}
                />
                <p className={styles.input_title}>Company Name</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.companyName}
                  name="companyName"
                  onChange={handleEditProfileChange}
                />
                <p className={styles.input_title}>Relationship</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.relationship}
                  name="relationship"
                  onChange={handleEditProfileChange}
                />
              </div>
              <div className={styles.profile_picture_section_right}>
                <p className={styles.input_title}>Last Name</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.lastname}
                  name="lastname"
                  onChange={handleEditProfileChange}
                />
                <p className={styles.input_title}>Phone Number</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.phoneNumber}
                  name="phoneNumber"
                  onChange={handleEditProfileChange}
                />
                <p className={styles.input_title}>Role</p>
                <AccountInput
                  isEditMode={isEditMode}
                  value={editProfile.position}
                  name="position"
                  onChange={handleEditProfileChange}
                />
              </div>
            </div>
          </div> */}
          <div className={styles.split_section}>
            <div className={styles.profile_picture_section_left}>
              <p className={styles.section_title}>Company Logo</p>
              <div className={styles.browse_logo_section}>
                <div className={styles.upload_text}>
                  <p
                    className={styles.choose_file_title}
                    onClick={handleChooseFileClick}
                  >
                    Browse Files to Upload
                  </p>
                  <p className={styles.choose_file_subtitle}>
                    Transparent Logo Suggested
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </div>
                <div className={styles.uploaded_logo}>
                  <img
                    //   src={selectedImage}
                    src={
                      selectedImage ||
                      companyData.logo_image ||
                      "/DefaultAccountImage.png"
                    }
                    alt="Uploaded Logo"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
              </div>
              {/* <p className={styles.input_title}>First Name</p>
              <AccountInput
                isEditMode={isEditMode}
                value={editProfile.firstname}
                name="firstname"
                onChange={handleEditProfileChange}
              />
              <p className={styles.input_title}>Country</p>
              <AccountInput
                isEditMode={isEditMode}
                value={editProfile.country}
                name="country"
                onChange={handleEditProfileChange}
              /> */}
            </div>
            <div className={styles.section}>
              <p className={styles.section_title}>Color Scheme</p>
              <div className={styles.color_picker_layout}>
                <div className={styles.color_picker}>
                  <ColorPicker
                    value={companyData.primary_color}
                    //   width="530"
                    onChange={handleColorChange}
                    hideOpacity={true}
                    hidePresets={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function AccountInput({ isEditMode, value, name, onChange }) {
  const handleInput = (e) => {
    if (name === "phoneNumber") {
      // Allow only digits
      const nonDigits = /[^0-9]/g;
      if (nonDigits.test(e.data)) {
        e.target.value = e.target.value.replace(nonDigits, "");
      }
    }
  };

  return (
    <>
      {isEditMode ? (
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          onInput={name === "phoneNumber" ? handleInput : null}
          className={styles.single_line_input_editable}
        />
      ) : (
        <div className={styles.single_line_input}>{value}</div>
      )}
    </>
  );
}
