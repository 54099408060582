import React, { createContext, useState, useEffect } from "react";

import { db } from "../services/firebase";
import {
  onSnapshot,
  orderBy,
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../services/firebase"; // Import storage separately
import { extractDatabaseReferance } from "../utils/extractDatabaseReferance";

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState(null);
  const [companyDomain, setCompanyDomain] = useState(null);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [companyDatabaseReference, setCompanyDatabaseReference] =
    useState(null);

  const extractDomainNameFromUrl = () => {
    const path = window.location.pathname.split("/");
    const domainName = path.length > 1 ? path[1] : null;
    return domainName;
  };

  const fetchCompanyData = async (domainName) => {
    setCompanyLoading(true);
    console.log("domainName", domainName);
    try {
      const docRef = extractDatabaseReferance();
      setCompanyDatabaseReference(docRef);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCompanyData(docSnap.data());
        return true;
      } else {
        setCompanyData(null);
        return false;
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
      setCompanyData(null);
      return false;
    } finally {
      setCompanyLoading(false);
    }
  };

  const getPrimaryColorWithOpacity = (opacity) => {
    if (companyData && companyData.primary_color) {
      const rgbValues = companyData.primary_color.match(/\d+/g);
      if (rgbValues && rgbValues.length === 3) {
        const [r, g, b] = rgbValues;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }
    }
    return "rgba(0, 0, 0, 0)";
  };

  const updateNexusLogoImage = async (file) => {
    try {
      const domainName = extractDomainNameFromUrl();
      const singleDocId = "default";
      const docRef = doc(db, domainName, singleDocId);

      const storageRef = ref(storage, `nexus_app_logos/${domainName}`);

      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);

      await updateDoc(docRef, {
        logo_image: downloadURL,
      });

      setCompanyData((prevData) => ({
        ...prevData,
        logo_image: downloadURL,
      }));

      console.log("Profile picture updated successfully.");
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        companyData,
        setCompanyData,
        companyDomain,
        setCompanyDomain,
        companyDatabaseReference,
        companyLoading,
        setCompanyLoading,
        fetchCompanyData,
        getPrimaryColorWithOpacity,
        updateNexusLogoImage,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
