import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import ConnectionCard from "./ConnectionCard";
import LoadingAnimation from "../UniversalComponents/LoadingAnimation";
import { ChatContext } from "../../../context/ChatContext";

export default function ConnectionLayout(props) {
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const sortByDropdownOptions = ["Date Joined", "A - Z", "Z - A"];
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortByDropdownOptions[0]
  );

  const { connections, loadingConnections, allUsers } = useContext(ChatContext);
  const [hoveredSection, setHoveredSection] = useState(null);
  console.log(allUsers);
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const SortDropdown = () => (
    <div className={styles.sortDropdown}>
      {sortByDropdownOptions.map((option) => (
        <div
          key={option}
          className={
            option === selectedSortOption
              ? styles.sortDropdownItemClicked
              : styles.sortDropdownItem
          }
          onClick={() => handleDropdownSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );

  const handleDropdownSelect = (option) => {
    setSelectedSortOption(option);
    setIsDropdownVisible(false);
    // Add sorting logic here if needed
  };

  const handleCreatorCheck = (index, isChecked) => {
    if (isChecked) {
      setSelectedCreators((prev) => [...prev, index]);
    } else {
      setSelectedCreators((prev) => prev.filter((id) => id !== index));
    }
  };

  function handleCheckboxClick(event) {
    event.stopPropagation();
  }

  return (
    <div className={styles.connection_layout}>
      <div className={styles.creator_list_controls}>
        <div className={styles.creator_list_left_controls}>
          <p className={styles.creator_found_title}>Members</p>
        </div>
        <div className={styles.creator_list_right_controls}>
          <div className={styles.sort_section} onClick={toggleDropdown}>
            <img
              className={styles.sort_icon}
              src="/SortIcon.png"
              alt="sort"
            ></img>
            <p className={styles.sort_text}>
              Sort by
              <span className={styles.chosen_sort_text}>
                {selectedSortOption}
              </span>
            </p>
            <img
              src="/images/general_icons/DownArrowIcon.png"
              alt="dropdown"
              className={`${styles.dropdown_arrow_sort} ${
                isDropdownVisible ? styles.flipped : ""
              }`}
            />
          </div>
          {isDropdownVisible && <SortDropdown />}
        </div>
      </div>
      <>
        <div className={styles.creator_list}>
          {allUsers.map((user, index) => {
            return (
              <ConnectionCard
                key={index}
                user={user}
                onCheck={handleCreatorCheck}
                index={index}
                activeTab={props.activeTab}
              />
            );
          })}
        </div>
      </>
    </div>
  );
}
