import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase"; // Import storage separately
import {
  collection,
  query,
  doc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { extractDatabaseReferance } from "../utils/extractDatabaseReferance";
import { FeedContext } from "./FeedContext";
export const AdminPortalContext = createContext();

export const AdminPortalProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [hubSpotContacts, setHubSpotContacts] = useState([]);

  const extractDomainNameFromUrl = () => {
    const path = window.location.pathname.split("/");
    const domainName = path.length > 1 ? path[1] : null;
    return domainName;
  };

  const fetchAllUsers = async () => {
    try {
      const usersRef = collection(extractDatabaseReferance(), "Accounts");
      const querySnapshot = await getDocs(usersRef);

      const users = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          users.push({ id: doc.id, ...userData });
        }
      });
      setAllUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const handleAnnouncement = async (newAnnouncement) => {
    try {
      const postsRef = collection(extractDatabaseReferance(), "Announcements");

      await addDoc(postsRef, newAnnouncement);
    } catch (error) {
      console.error("Error posting:", error);
      throw error;
    }
  };

  const deleteAnnouncementById = async (id) => {
    try {
      const programRef = doc(extractDatabaseReferance(), "Announcements", id);
      await deleteDoc(programRef);
      console.log("Program deleted successfully");
    } catch (error) {
      console.error("Error deleting program:", error);
      throw error;
    }
  };

  // const deleteAnnouncement = async (id) => {
  //   try {
  //     const postRef = doc(extractDatabaseReferance(), "Announcements", id);
  //     await deleteDoc(postRef);
  //     setFeed((prev) => prev.filter((item) => item.id !== id));
  //     console.log("Announcement deleted successfully.");
  //   } catch (error) {
  //     console.error("Error deleting post:", error);
  //   }
  // };

  const createProgram = async (newProgram) => {
    try {
      const postsRef = collection(extractDatabaseReferance(), "Programs");

      await addDoc(postsRef, newProgram);
    } catch (error) {
      console.error("Error Adding Program:", error);
      throw error;
    }
  };

  const deleteProgramById = async (programId) => {
    try {
      const programRef = doc(extractDatabaseReferance(), "Programs", programId);

      await deleteDoc(programRef);
      console.log("Program deleted successfully");
    } catch (error) {
      console.error("Error deleting program:", error);
      throw error;
    }
  };

  const updateProgram = async (newAnnouncement) => {
    try {
      const postsRef = collection(extractDatabaseReferance(), "Announcements");

      await addDoc(postsRef, newAnnouncement);
    } catch (error) {
      console.error("Error posting:", error);
      throw error;
    }
  };

  // const deleteProgram = async (id) => {
  //   try {
  //     const postRef = doc(extractDatabaseReferance(), "Posts", id);
  //     await deleteDoc(postRef);
  //     setFeed((prevFeed) => prevFeed.filter((post) => post.id !== id));
  //     console.log("Post deleted successfully.");
  //   } catch (error) {
  //     console.error("Error deleting post:", error);
  //   }
  // };

  return (
    <AdminPortalContext.Provider
      value={{
        allUsers,
        fetchAllUsers,
        handleAnnouncement,
        deleteAnnouncementById,
        createProgram,
        deleteProgramById,
      }}
    >
      {children}
    </AdminPortalContext.Provider>
  );
};
