import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { FeedProvider } from "./context/FeedContext";
import { CompanyProvider } from "./context/CompanyContext";
import LoadingPage from "./pages/LoadingPage";
import { ChatProvider } from "./context/ChatContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <CompanyProvider>
      <AuthProvider>
        <FeedProvider>
          <ChatProvider>
            <Routes />
          </ChatProvider>
        </FeedProvider>
        <ToastContainer />
      </AuthProvider>
    </CompanyProvider>
  </>
);

// reportWebVitals(console.log)
reportWebVitals();
