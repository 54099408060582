import React from "react";
import { useState } from "react";
import styles from "./adminusers.module.css";
import UserIcon from "../../../assets/SVG/UserIcon";

export default function UserCard(props) {
  const user = props.profile;

  function formatTimestampToDate(timestamp) {
    try {
      // Convert timestamp to milliseconds if it's not already
      const date = new Date(parseInt(timestamp, 10));

      // Options for toLocaleDateString to get desired format
      const options = { year: "numeric", month: "long", day: "numeric" };

      // Formatting the date
      return date.toLocaleDateString(undefined, options);
    } catch (error) {
      console.error("Error in formatting timestamp:", error);
      return "";
    }
  }

  return (
    <div className={styles.user_profile}>
      <div className={styles.user_profile_layout}>
        <div className={styles.user_profile_image}>
          {user?.profilePicture ? (
            <img
              className={styles.user_profile_image}
              src={user?.profilePicture}
              alt="Profile"
            />
          ) : (
            <UserIcon color={props.color} />
          )}
        </div>

        <div className={styles.user_profile_text}>
          <p className={styles.user_profile_name}>
            {user.first_name} {user.last_name}
          </p>
          <p className={styles.user_profile_genre}>
            {user.admin ? "Admin" : "Member"}
          </p>
        </div>
      </div>
      {/* <div className={styles.column_title}>
        {formatTimestampToDate(user.accountCreatedDate)}
      </div>
      <div className={styles.column_title}>{user?.age}</div> */}
      {/* <div className={styles.column_title_last}>Edit</div> */}
    </div>
  );
}
