import React from "react";
import { useContext, useState, useEffect } from "react";
import styles from "./adminusers.module.css";
import SearchBarHeader from "../../UniversalComponents/SearchbarHeader/SearchbarHeader";
import UserCard from "./adminUserCard";
import { AdminPortalContext } from "../../../context/AdminPortalContext";
import { CompanyContext } from "../../../context/CompanyContext";
import { ConfirmDeletePopup } from "../../ScreenPopups/DeletePopup/ConfirmDeletePopup";
import { ManageUserPopup } from "../../ScreenPopups/ManageUserPopup/ManageUserPopup";

function AdminUsersPage() {
  const { allUsers, fetchAllUsers } = useContext(AdminPortalContext);
  const { companyData } = useContext(CompanyContext);
  const [filteredUsers, setFilteredUsers] = useState(allUsers);

  const [searchTerm, setSearchTerm] = useState("");
  const [manageUserPopupOpen, setManageUserPopupOpen] = useState(false);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const filtered = allUsers.filter((user) =>
      user?.first_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [allUsers, searchTerm]);

  function createUser() {
    // setManageUserPopupOpen(true);
  }

  function editUser() {
    setManageUserPopupOpen(true);
  }

  function saveUserInformation() {
    return;
  }

  return (
    <div className={styles.main_layout}>
      <ManageUserPopup
        openModal={manageUserPopupOpen}
        closeModal={() => setManageUserPopupOpen(false)}
        deleteFtn={(event) => {
          saveUserInformation(event);
        }}
        desc={"Add New User?"}
      />
      <p className={styles.page_title}>{companyData.company_name} Users</p>
      <SearchBarHeader
        type="AdminUsers"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        create={true}
        createFunction={createUser}
      />
      <UserList filteredUsers={filteredUsers} companyData={companyData} />
    </div>
  );
}

export function UserList({ filteredUsers, companyData }) {
  return (
    <>
      <div className={styles.column_titles}>
        <p className={styles.column_title_info}>Basic Info</p>
      </div>
      {filteredUsers.length !== 0 && (
        <div className={styles.prospect_scroll}>
          {filteredUsers.map((profile, index) => {
            return (
              <UserCard
                key={index}
                profile={profile}
                color={companyData.primary_color}
              />
            );
          })}
          <div className={styles.buffer}></div>
        </div>
      )}
    </>
  );
}

export default AdminUsersPage;
